import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";

import Loader from "components/Loader/Loader";

import { getRequest } from "utils/ApiServices";

import XRaceCarsLogo from "Assets/xrace-cars-logo.png";

import classes from "./Totalbox.module.css";

const Toolbox = () => {
  let history = useHistory();
  const [pageHash_, setPageHash] = useState();
  const [embedData, setEmbedData] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    getUser();
    getEmbedVideo();
  }, []);

  useEffect(() => {
    const parsedHash = queryString.parse(window.location.hash);
    let pageHash = Number(Object.keys(parsedHash)[0]);
    setPageHash(pageHash);
  });

  useEffect(() => {
    if (currentUser) {
      if (currentUser.reward === "Reward #1") {
        history.push("/rew#1");
      } else if (currentUser.reward === "Reward #2") {
        history.push("/rew#2");
      } else if (currentUser.reward === "Reward #3") {
        history.push("/rew#3");
      } else if (currentUser.reward === "Reward #4") {
        history.push("/rew#4");
      }
    }
  }, [pageHash_]);

  const getUser = async () => {
    setOpenLoader(true);
    const route = "/user/me";
    try {
      const userData = await getRequest(route);
      setOpenLoader(false);
      if (userData.payment.reward === "Reward #1") {
        history.push("/rew#1");
      } else if (userData.payment.reward === "Reward #2") {
        history.push("/rew#2");
      } else if (userData.payment.reward === "Reward #3") {
        history.push("/rew#3");
      } else if (userData.payment.reward === "Reward #4") {
        history.push("/rew#4");
      }
    } catch (err) {
      setOpenLoader(false);
    }
  };
  const getBaseUrlVideo = (lnk) => {
    var pathArray = lnk.split("/");
    var protocol = pathArray[0];
    var host = pathArray[2];
    var url = protocol + "//" + host;
    return url;
  };
  const getEmbedVideo = async () => {
    setOpenLoader(true);

    const route = "/admin/getUrl";
    try {
      const videoURL = await getRequest(route);
      setOpenLoader(false);
      if (videoURL[0] !== undefined) {
        setEmbedData(videoURL[0].video);
        getBaseUrlVideo(videoURL[0].video);
      }
    } catch (err) {
      setOpenLoader(false);
    }
  };

  const splitYoutubeUrl = (link) => {
    return "https://www.youtube.com/embed/" + link.split("?v=")[1];
  };

  return (
    <div className={classes.leftBar}>
      <Loader isOpen={openLoader} />

      <Grid container>
        <Grid style={{ margin: "auto" }} item lg={12} xs={12}>
          <div className="d-flex justify-content-center align-items-center">
            <div className={classes.text}>
              <p>TOTAL XCCESS (XCS)</p>
              <p> Crypto-currency Tokens</p>
              <div className={classes.box}>{currentUser?.payment?.tokens}</div>
            </div>
          </div>
          <p style={{ color: "white" }}>
            Become an Independent Sales Executive and earn a generous revenue and extra
            crypto-currency bonuses.
          </p>
        </Grid>
        <Grid
          style={{
            border: "1px solid white",
            borderRadius: "20px",
          }}
          item
          lg={12}
          xs={12}
        >
          <iframe
            className={classes.video2}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/mfYtktZxRZ4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Grid>

        <Grid className={classes.xracecars}>
          <p> The $999 Reward Plan coming upon the NFT release of our</p>
          <img src={XRaceCarsLogo} alt="" />{" "}
        </Grid>
      </Grid>
    </div>
  );
};
export default Toolbox;
