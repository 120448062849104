const apiURL = "https://ec2-3-15-157-65.us-east-2.compute.amazonaws.com:8080/";
// const apiURL = "http://localhost:8080";
const BUCKET_NAME = "xccess";
const REGION_NAME = "us-east-2";

module.exports = {
  apiURL,
  BUCKET_NAME,
  REGION_NAME,
};
