import React from "react";
import Radio from "@material-ui/core/Radio";

import classes from "../Checkout.module.css";

const data = [
  {
    reward: "Reward #1",
    ammount: 99,
    text: "500 Prelaunch XCCESS (XCS) Cryptocurrency Tokens",
    tokens: 500,
  },
  // {
  //   reward: "Reward #2",
  //   ammount: 299,
  //   text: "1,500 Prelaunch XCCESS (XCS) Cryptocurrency Tokens",
  //   tokens: 1500,
  // },
  // {
  //   reward: "Reward #3",
  //   ammount: 499,
  //   text: "2500 Prelaunch XCCESS (XCS) Cryptocurrency Tokens",
  //   tokens: 2500,
  // },
  // {
  //   reward: "Reward #4",
  //   ammount: 999,
  //   text: "5000 Prelaunch XCCESS (XCS) Cryptocurrency Tokens",
  //   tokens: 5000,
  // },
];
const PackageCard = ({ selectedValue, onPackageSelect }) => {
  const handleChange = (event, rew, t, tokenn) => {
    onPackageSelect(event.target.value, rew, t, tokenn);
  };

  return (
    <div className={classes.cardFlex}>
      {data.map((dt, i) => (
        <div className={classes.cardMain} key={i}>
          <div className={classes.cardContainer}>
            <h2>{dt.reward}</h2>
            <h3>${dt.ammount}</h3>
            <p>{dt.text}</p>
            <div>
              <Radio
                checked={selectedValue === dt.ammount.toString()}
                onChange={(e) => handleChange(e, dt.reward, dt.text, dt.tokens)}
                value={dt.ammount.toString()}
                name="radio-button-demo"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default PackageCard;
