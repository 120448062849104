import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFileUpload } from "use-file-upload";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";

import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";

import { patchRequest } from "utils/ApiServices";
import { uploadFile, getUploadedFileUrl } from "utils/s3Bucket";

import classes from "./Resources.module.css";

const Resources = () => {
  const history = useHistory();
  const [file, selectFile] = useFileUpload();
  const [selectedFile, setSelectedFile] = useState(null);
  const [openLoader, setOpenLoader] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    if (file) {
      if (file.file.type !== "application/pdf") {
        toast.error("Invalid file type");
      } else if (file.file.size > 2 * 1024 * 1024) {
        toast.error("PDF should be less than 2 MBs");
      } else {
        setSelectedFile(file);
      }
    }
  }, [file]);

  const onSubmitClick = () => {
    if (!selectedFile) {
      return toast.error("PDF is required before submitting");
    }

    setOpenLoader(true);
    try {
      const myBucket = uploadFile(selectedFile);
      myBucket
        .on("error", (err) => {
          if (err) return Promise.reject(err);
        })
        .on("httpUploadProgress", (evt) => {
          const progress = Math.round((evt.loaded / evt.total) * 100);
          if (progress === 100) {
            updateUrlToDb();
          }
        })
        .send();
    } catch (err) {
      toast.error("Something went wrong");
      setOpenLoader(false);
    }
  };

  const updateUrlToDb = async () => {
    const route = "/user/editUserData";
    const payload = {
      _id: currentUser._id,
      contract: getUploadedFileUrl(selectedFile),
    };
    try {
      await patchRequest(route, payload);
      setOpenLoader(false);
      toast.success("Successfully Updated");
    } catch (err) {
      setOpenLoader(false);
      toast.error("Something went wrong ");
    }
  };

  return (
    <div>
      <Loader isOpen={openLoader} />
      <Navbar />
      <div className={classes.gridMain}>
        <div className={classes.main}>
          <div className={classes.border}>
            <div className={classes.header}>For Independent Contractor/Representatives</div>
            <div className={classes.container}>
              <a href="/contract.pdf" download="contract.pdf" className={classes.downloadAnchor}>
                <p className={classes.text}>
                  <span className={classes.tick}>&#10003;</span>Click Here to READ, SIGN, DATE AND
                  UPLOAD <br />
                  <span className={classes.bulletPoints}>INDEPENDENT CONTRACTOR'S AGREEMENT</span>
                </p>
              </a>
              {selectedFile && <p className={classes.fileName}>{selectedFile.file.name}</p>}

              <div className={classes.buttonFlex}>
                <div>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => selectFile({ accept: "application/pdf" })}
                  >
                    UPLOAD
                  </Button>
                </div>
                <div>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={onSubmitClick}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => history.push("/profile")}
          >
            Required Information
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Resources;
