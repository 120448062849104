const initialState = JSON.parse(localStorage.getItem("user")) || null;

const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      state = JSON.parse(localStorage.getItem("user"));
      return state;

    case "RESET_CURRENT_USER":
      state = null;
      return state;

    default:
      return state;
  }
};

export default currentUserReducer;
