import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";

import { patchRequest } from "utils/ApiServices";

import classes2 from "../EditBonus.module.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "70%",
  },
}));
const EditBonusModal = ({ bonus, getUserBonus }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [bonus_, setBonus] = React.useState({
    commission: bonus.commission,
    bonus: bonus.bonus,
    status: bonus.status,
    id: bonus._id,
  });
  const [openLoader, setOpenLoader] = React.useState(false);

  React.useEffect(() => {
    setBonus(bonus);
  }, [bonus]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (bonus_.commission === "" && bonus._bonus === "") {
      toast.error("Please enter commission and cryptocurrency");
    } else if (bonus_.commission === "" && bonus._bonus !== "") {
      toast.error("Please enter commission ");
    } else if (bonus_.commission !== "" && bonus._bonus === "") {
      toast.error("Please enter cryptocurrency");
    } else {
      setOpenLoader(true);
      const route = "/user/editRefferBonus";
      try {
        await patchRequest(route, bonus_);
        setOpenLoader(false);
        toast.success("Successfully Saved");
        getUserBonus();
      } catch (err) {
        setOpenLoader(false);
        toast.error("Something went wrong ");
      }
    }
  };
  return (
    <div>
      <Loader isOpen={openLoader} />
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Edit Data
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes2.form}>
              <div className={classes2.container}>
                <div className={classes2.heading}>
                  <h2>Edit Commission</h2>
                </div>
                <div className={classes2.commision}>
                  <h2>Commision</h2>
                  <input
                    type="text"
                    className={classes2.input}
                    value={bonus_.commission}
                    onChange={(e) => setBonus({ ...bonus_, commission: e.target.value })}
                  />
                </div>
                <div className={classes2.crypto}>
                  <h2>Cryptocurrency</h2>
                  <input
                    type="text"
                    className={classes2.input}
                    value={bonus_.bonus}
                    onChange={(e) => setBonus({ ...bonus_, bonus: e.target.value })}
                  />
                </div>
                <div className={classes2.status}>
                  <h2>Status</h2>
                  <select
                    value={bonus_.status}
                    onChange={(e) => setBonus({ ...bonus_, status: e.target.value })}
                    className={classes2.select}
                  >
                    <option value="pending">Pending</option>
                    <option value="paid">Paid</option>
                    <option value="chargeback">Chargeback</option>
                  </select>
                </div>
                <div className={classes2.save}>
                  <Button variant="contained" color="primary" onClick={onSubmit}>
                    Save Changes!
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default EditBonusModal;
