import React from "react";

import chart1 from "Assets/commission-chart.jpg";

import classes from "./ChartsAndFlyers.module.css";

const ChartsAndFlyers = () => {
  return (
    <div className={classes.chartContainer}>
      <div className={classes.referralButton}>
        <h2>Referral Commission and XCCESS Bonus</h2>
      </div>

      <div className={classes.chartImage}>
        <img src={chart1} alt="chart1" />
      </div>
    </div>
  );
};

export default ChartsAndFlyers;
