import { toast, Flip } from "react-toastify";

const options = {
  position: "bottom-center",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
  transition: Flip,
};

const toaster = {
  success: (msg) => {
    toast.success(`✓ ${msg}`, options);
  },
  error: (msg) => {
    toast.error(`✘ ${msg}`, options);
  },
  info: (msg) => {
    toast.info(`✉ ${msg}`, options);
  },
};

export default toaster;
