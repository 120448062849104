import AWS from "aws-sdk";
import store from "redux/store";

import { BUCKET_NAME, REGION_NAME } from "../config/config";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: BUCKET_NAME },
  region: REGION_NAME,
});

const currentUser = store.getState().currentUser;

// uploadFile
export const uploadFile = (selectedFile) => {
  const params = {
    ACL: "public-read",
    Body: selectedFile.file,
    Bucket: BUCKET_NAME,
    Key: `${currentUser._id}.${selectedFile.file.type.split("/")[1]}`,
  };

  return myBucket.putObject(params);
};

// getUploadedFileUrl
export const getUploadedFileUrl = (selectedFile) => {
  let tempUrl;
  tempUrl = `https://${BUCKET_NAME}.s3.amazonaws.com/${currentUser._id}.${
    selectedFile.file.type.split("/")[1]
  }`;
  return tempUrl;
};
