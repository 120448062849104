import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import classes from "./Leftbar.module.css";

const Leftbar = () => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <div className={classes.leftBar}>
      <div className={classes.name}>
        <h2 className={classes.nameTitle}>
          {currentUser?.firstname.toLowerCase()} {currentUser?.lastname.toLowerCase()}
        </h2>
        <p>{currentUser?.coupon}</p>
      </div>
      <div className={classes.commissionDiv}>
        <div>
          <Link to="/commission-and-bonus" className={classes.links}>
            Commission and XCCESS Bonuses
          </Link>
        </div>
        <div className={classes.links2Div}>
          <Link to="/resources" className={classes.links}>
            Resources
          </Link>
        </div>
        <div className={classes.links2Div}>
          <Link to="/referral-commission-and-bonus" className={classes.links}>
            Referral Commission & Bonus
          </Link>
        </div>
        <div className={classes.links2Div}>
          <Link to="/commission-payment-method" className={classes.links}>
            Commission Payment Set-up
          </Link>
        </div>
        <div className={classes.links2Div}>
          <Link to="/financial-literacy" className={classes.links}>
            Financial & Digital Literacy eBook
          </Link>
        </div>
        <div className={classes.links2Div}>
          <Link to="/opportunity-video" className={classes.links}>
            XCCESS Opportunity Video
          </Link>
        </div>
        <div className={classes.links2Div}>
          <Link to="/digital-wallet-address" className={classes.links}>
            Digital Wallet Address
          </Link>
        </div>
        <div className={classes.links2Div}>
          <Link to="/web3-online-training" className={classes.links}>
            Web3.0 Online Training
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Leftbar;
