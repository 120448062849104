import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import Container from "@material-ui/core/Container";

import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import Loader from "components/Loader/Loader";
import Banner from "./components/Banner/Banner";
import DataTable from "./components/DataTable/DataTable";

import { getRequest } from "utils/ApiServices";

import classes from "./CommissionAndBonus.module.css";

const CommissionAndBonus = () => {
  const [referalData, setReferalData] = React.useState();
  const [openLoader, setOpenLoader] = React.useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    getReferalData();
  }, []);

  const getReferalData = async () => {
    setOpenLoader(true);
    const route = `/user/getReferal?id=${currentUser._id}`;
    try {
      const referalData = await getRequest(route);
      setOpenLoader(false);
      setReferalData(referalData);
    } catch (err) {
      setOpenLoader(false);
    }
  };

  return (
    <div>
      <Navbar />
      <Loader isOpen={openLoader} />
      <div className={classes.background}>
        <Container className={classes.container}>
          <div className={classes.height}>
            {/* <Banner /> */}
            <DataTable data={referalData} dta="hello" openLoader={openLoader} />
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default CommissionAndBonus;
