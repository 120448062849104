import { createBrowserHistory } from "history";
import { postRequest } from "utils/ApiServices";

import { resetCurrentUser } from "redux/actions";
import store from "redux/store";

const logoutHandler = async () => {
  createBrowserHistory().push("/");
  window.location.reload();
  localStorage.removeItem("user");
  store.dispatch(resetCurrentUser());
  const route = "/user/logout";
  try {
    await postRequest(route);
  } catch (err) {
    console.log(err);
  }
};

export default logoutHandler;
