import classes from "../EditBonus.module.css";
import React from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const WalletAddressCard = ({ userData }) => {
  return (
    <div className={classes.mainLicence}>
      <div className={classes.licenceCard}>
        <div className={classes.licenceHeader}>
          <div className={classes.container}>
            <p>Digital Wallet Address</p>
          </div>
        </div>

        <div style={{ padding: "40px 10px 10px 10px", overflow: "auto" }}>
          {userData ? (
            <div style={{}}>
              <Grid container>
                <Grid item xs={4}>
                  <p className={classes.paymentTitle}>Wallet Address: </p>
                </Grid>
                <Grid item xs={8}>
                  {userData?.walletAddress ? (
                    <p>{userData?.walletAddress}</p>
                  ) : (
                    <TextField size="small" variant="outlined" disabled />
                  )}
                </Grid>
              </Grid>
            </div>
          ) : (
            <p>No data found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletAddressCard;
