import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setCurrentUser } from "redux/actions";
import logoutHandler from "utils/logoutHandler";
import checkCurrentUserKeys from "utils/checkCurrentUserKeys";

function UserAuth({ component: Component, ...restOfProps }) {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    if (currentUser && checkCurrentUserKeys(currentUser)) {
      dispatch(setCurrentUser());
      if (["ceo", "super-admin", "admin", "user"].includes(currentUser.usertype)) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } else {
      logoutHandler();
    }
  }, []);

  // const getMe = async () => {
  //   const route = "/user/me";
  //   try {
  //     const apiResp = await getRequest(route);
  //     if (apiResp) {
  //       setIsLoggedIn(true);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     setIsLoggedIn(false);
  //     setLoading(false);
  //     localStorage.removeItem("user");
  //   }
  // };

  // useEffect(() => {
  //   getMe();
  // }, []);

  return (
    <Route
      {...restOfProps}
      render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/" />)}
    />
    // <Route
    //   {...restOfProps}
    //   render={(props) =>
    //     isLoggedIn === true && loading === false ? (
    //       <Component {...props} />
    //     ) : (
    //       <Redirect to="/" />
    //     )
    //   }
    // />
  );
}

export default UserAuth;
