export const setCurrentUser = () => {
  return {
    type: "SET_CURRENT_USER",
  };
};

export const resetCurrentUser = () => {
  return {
    type: "RESET_CURRENT_USER",
  };
};
