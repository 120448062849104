import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";

import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";

import { getRequest, patchRequest } from "utils/ApiServices";

import classes from "./DigitalWalletContent.module.css";

const DigitalWalletContent = () => {
  const [userIs, setUserIs] = useState();
  const [walletAddress, setWalletAddress] = useState("");
  const [openLoader, setOpenLoader] = React.useState(false);

  useEffect(() => {
    const userDataAPI = async () => {
      if (localStorage.getItem("user")) {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const route = `/user/userData/${currentUser._id}`;
        setOpenLoader(true);
        try {
          const userData = await getRequest(route);
          setOpenLoader(false);
          setUserIs(userData);
          setWalletAddress(userData.walletAddress);
        } catch (err) {
          setOpenLoader(false);
          toast.error("Something went wrong");
        }
      }
    };
    userDataAPI();
  }, []);

  const onSubmitHandler = async () => {
    if (!userIs) return;
    const payload = {
      _id: userIs._id,
      walletAddress: walletAddress,
    };

    setOpenLoader(true);
    const route = "/user/addWalletAddress";
    try {
      await patchRequest(route, payload);
      setOpenLoader(false);
      toast.success("Wallet Address Successfully Saved");
    } catch (err) {
      setOpenLoader(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className={classes.DigitalWalletContentContainer}>
      <Loader isOpen={openLoader} />
      <h2>Digital Wallet Address</h2>

      <div className={classes.infoText}>
        <p>
          Please enter your Public Pera Wallet address below to be sent your allocated XCCESS Crypto
          Tokens and Bonuses at the time we launch on exchanges. <br /> Not sure how this is done?{" "}
          <br />
          Don't Worry! We teach you how in our monthly Digital Wallet Onboard Training. We’ll
          contact you for next training via email.{" "}
        </p>
      </div>

      <div className={classes.noteText}>
        <p>
          NOTE: By entering your wallet address you agree it is correct and indemnify XCCESS, LLC of
          all liability if the wallet address you submit is incorrect.
          <br />
          <span>
            Make certain your digital wallet address is correct to prevent lost of your XCCESS
            Crypto when transferred.
          </span>
        </p>
      </div>

      <div className={classes.inputFieldContainer}>
        <label>Enter Wallet Address</label> <br />
        <input
          type="text"
          name="walletAddress"
          defaultValue={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
        />
      </div>

      <Button variant="contained" className={classes.submitBtn} onClick={onSubmitHandler}>
        Submit
      </Button>
    </div>
  );
};

export default DigitalWalletContent;
