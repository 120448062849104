export const commissionCheck = (amount, newAmount2) => {
  let newAmount;
  let commisionBonus = {
    commission: "",
    bonus: "",
  };
  newAmount = newAmount2 / (90 / 100);
  if (amount === 99 || amount === 89) {
    if (newAmount === 99) {
      commisionBonus = {
        commission: 35,
        bonus: "500",
      };
    } else if (newAmount === 299) {
      commisionBonus = {
        commission: 45,
        bonus: "750",
      };
    } else if (newAmount === 499) {
      commisionBonus = {
        commission: 55,
        bonus: "1000",
      };
    } else if (newAmount === 999) {
      commisionBonus = {
        commission: 110,
        bonus: "1250",
      };
    }
  } else if (amount === 299 || amount === 269) {
    if (newAmount === 99) {
      commisionBonus = {
        commission: 35,
        bonus: "500",
      };
    } else if (newAmount === 299) {
      commisionBonus = {
        commission: 55,
        bonus: "1500",
      };
    } else if (newAmount === 499) {
      commisionBonus = {
        commission: 70,
        bonus: "1750",
      };
    } else if (newAmount === 999) {
      commisionBonus = {
        commission: 140,
        bonus: "2000",
      };
    }
  } else if (amount === 499 || amount === 449) {
    if (newAmount === 99) {
      commisionBonus = {
        commission: 35,
        bonus: "500",
      };
    } else if (newAmount === 299) {
      commisionBonus = {
        commission: 65,
        bonus: "1750",
      };
    } else if (newAmount === 499) {
      commisionBonus = {
        commission: 85,
        bonus: "2500",
      };
    } else if (newAmount === 999) {
      commisionBonus = {
        commission: 170,
        bonus: "3250",
      };
    }
  } else if (amount === 999 || amount === 899) {
    if (newAmount === 99) {
      commisionBonus = {
        commission: 35,
        bonus: "500",
      };
    } else if (newAmount === 299) {
      commisionBonus = {
        commission: 75,
        bonus: "2000",
      };
    } else if (newAmount === 499) {
      commisionBonus = {
        commission: 100,
        bonus: "2750",
      };
    } else if (newAmount === 999) {
      commisionBonus = {
        commission: 200,
        bonus: "5000",
      };
    }
  }
  return commisionBonus;
};
