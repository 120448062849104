import "./App.css";
import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import "react-toastify/dist/ReactToastify.css";

// Public Pages
import Homepage from "./pages/PublicPages/Homepage/Homepage";
import TermsConditions from "./pages/PublicPages/TermsConditions/TermsConditions";
import PrivacyPolicy from "./pages/PublicPages/PrivacyPolicy/PrivacyPolicy";
import RequestNewCode from "./pages/PublicPages/RequestNewCode/RequestNewCode";
import EmailVerification from "./pages/PublicPages/EmailVerification/EmailVerification";
// User Pages
import PaidSubDash from "./pages/User/PaidSubDash/PaidSubDash";
import CommissionAndBonus from "./pages/User/CommissionAndBonus/CommissionAndBonus";
import Resources from "./pages/User/Resources/Resources";
import BonusCharts from "./pages/User/BonusCharts/BonusCharts";
import CommissionPaymentMethod from "./pages/User/CommissionPaymentMethod/CommissionPaymentMethod";
import FinancialLiteracy from "./pages/User/FinancialLiteracy/FinancialLiteracy";
import OpportunityVideo from "./pages/User/OpportunityVideo/OpportunityVideo";
import DigitalWalletAddress from "pages/User/DigitalWalletAddress/DigitalWalletAddress";
import Web3OnlineTraining from "pages/User/Web3OnlineTraining/Web3OnlineTraining";
import Profile from "./pages/User/Profile/Profile";
// Admin Pages
import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import EditBonus from "./pages/Admin/EditBonus/EditBonus";

import PublicUserAuth from "./auth/PublicUserAuth";
import UserAuth from "./auth/UserAuth";
import AdminAuth from "./auth/AdminAuth";

function App() {
  React.useEffect(() => {
    // if (initialState.token) {
    //   if (isJwtExpired(initialState.token)) {
    //     localStorage.removeItem("user");
    //     localStorage.removeItem("localToken");
    //     localStorage.removeItem("useId");
    //     localStorage.removeItem("type");
    //   }
    // }
  }, []);

  return (
    <Router>
      <ToastContainer />
      <CssBaseline />
      <Switch>
        {/* PublicRoutes */}
        <PublicUserAuth exact path="/" component={Homepage} />
        <PublicUserAuth path="/terms-conditions" component={TermsConditions} />
        <PublicUserAuth path="/privacy-policy" component={PrivacyPolicy} />
        <PublicUserAuth path="/request-new-code/:email/" component={RequestNewCode} />
        <PublicUserAuth
          path="/email-verification/:randomstring/:email/"
          component={EmailVerification}
        />
        {/* UserRoutes */}
        <UserAuth path="/rew" component={PaidSubDash} />
        <UserAuth path="/commission-and-bonus" component={CommissionAndBonus} />
        <UserAuth path="/profile" component={Profile} />
        <UserAuth path="/resources" component={Resources} />
        <UserAuth path="/referral-commission-and-bonus" component={BonusCharts} />
        <UserAuth path="/commission-payment-method" component={CommissionPaymentMethod} />
        <UserAuth path="/financial-literacy" component={FinancialLiteracy} />
        <UserAuth path="/opportunity-video" component={OpportunityVideo} />
        <UserAuth path="/digital-wallet-address" component={DigitalWalletAddress} />
        <UserAuth path="/web3-online-training" component={Web3OnlineTraining} />
        {/* AdminRoutes */}
        <AdminAuth exact path="/admin" component={Dashboard} />
        <AdminAuth path="/admin/user/:userId" component={EditBonus} />
      </Switch>
    </Router>
  );
}

export default App;
