import React from "react";
import { useHistory } from "react-router-dom";
import { useModal } from "react-simple-hook-modal";
import { useSelector } from "react-redux";

import { Button, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Dashboard from "@material-ui/icons/Dashboard";
import ContactSupportOutlined from "@material-ui/icons/ContactSupportOutlined";
import ExitToApp from "@material-ui/icons/ExitToApp";

import Login from "./Modal/Login";
import ContactUsModal from "./Modal/ContactUsModal";

import logoutHandler from "utils/logoutHandler";

import MyxfundLogo from "Assets/myxfund-logo.png";
import classes from "./Navbar.module.css";

const Navbar = () => {
  const { isModalOpen, openModal, closeModal } = useModal();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentUser = useSelector((state) => state.currentUser);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.main}>
      <div className={classes.flex}>
        <div className={classes.flex1}>
          <img
            className={classes.MyxfundLogo}
            src={MyxfundLogo}
            alt="MyxfundLogo"
            onClick={() => history.push("/")}
          />
        </div>
        <div className={classes.flex2}>
          <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              {currentUser && ["ceo", "super-admin", "admin"].includes(currentUser?.usertype) ? (
                <MenuItem onClick={() => history.push("/admin")}>
                  <ListItemIcon>
                    <SupervisorAccount />
                  </ListItemIcon>
                  Admin Panel
                </MenuItem>
              ) : null}
              {currentUser && (
                <MenuItem onClick={() => history.push("/rew")}>
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  Dashboard
                </MenuItem>
              )}
              {currentUser ? (
                <MenuItem onClick={openModal}>
                  <ListItemIcon>
                    <ContactSupportOutlined />
                  </ListItemIcon>
                  Contact
                </MenuItem>
              ) : null}
              {currentUser ? (
                <MenuItem onClick={logoutHandler}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              ) : null}
            </Menu>

            <div className={classes.mobileButtonFlex}>
              {!currentUser ? (
                <>
                  <div>{!currentUser ? <Login from="mobile" /> : null}</div>
                  <div>
                    <Button
                      onClick={openModal}
                      style={{ marginRight: "10px" }}
                      variant="outlined"
                      className={classes.supportButtonMobile}
                      size="small"
                    >
                      Contact
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.userAvatar} onClick={handleClick}>
                    {currentUser?.firstname?.charAt(0)}
                    {currentUser?.lastname?.charAt(0)}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ContactUsModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};
export default Navbar;
