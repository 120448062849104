import React, { useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Modal, Backdrop, Fade } from "@material-ui/core";

import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";

import { postRequest } from "utils/ApiServices";
import { parseError } from "utils/helpers";

import classes2 from "../Dashboard.module.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: "black",
    minWidth: "400px",
    textAlign: "center",
  },
}));

const SsnLogin = ({ id, onViewSsn, userSsn, setUserSsn, userData, userType }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSsn, setShowSsn] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowSsn(false);
    setUserSsn("");
  };

  const onSubmitHandler = async () => {
    setOpenLoader(true);
    if (userType === "ceo" || userType === "super-admin") {
      const route = "/user/login";
      const payload = {
        email: email,
        password: password,
      };
      try {
        const respData = await postRequest(route, payload);
        setOpenLoader(false);
        if (respData._id === currentUser._id) {
          if (respData.usertype === "ceo" || respData.usertype === "super-admin") {
            toast.success("Login Successfully");
            onViewSsn(true, id);
            setShowSsn(true);
          }
        } else {
          toast.error("Please login with same crdentials");
        }
      } catch (err) {
        setOpenLoader(false);
        if (err) {
          toast.error(parseError(err));
        }
      }
    } else {
      setOpenLoader(false);
      toast.error("You're not authorized to view ssn");
    }
  };

  return (
    <div>
      <Loader isOpen={openLoader} />
      <Button size="small" variant="contained" color="secondary" onClick={() => handleOpen()}>
        View SSN
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {!showSsn ? (
            <div className={classes.paper}>
              <div className={classes2.form}>
                <h1>Login</h1>
                <p>Please Login again to view ssn</p>
                <div>
                  <input
                    type="text"
                    placeholder="Email"
                    required=""
                    id="email"
                    value={email}
                    className={classes2.ssnInput}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="Password"
                    required=""
                    id="password"
                    value={password}
                    className={classes2.ssnInput}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes2.ssnSubmit}
                    onClick={onSubmitHandler}
                  >
                    Log In
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.paper}>
              <h2 className={classes2.ssnHeading}>User SSN </h2>
              <p id="transition-modal-description">
                {userSsn === undefined ? "Not Found" : userSsn}
              </p>
            </div>
          )}
        </Fade>
      </Modal>
    </div>
  );
};
export default SsnLogin;
