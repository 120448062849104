import React from "react";
import { ModalProvider, Modal, ModalTransition } from "react-simple-hook-modal";

import ContactUs from "Assets/contact-us.jpg";
import classes from "./ContactUsModal.module.css";

const ContactUsModal = (props) => {
  return (
    <ModalProvider>
      <Modal
        id={classes.contactModal}
        isOpen={props.isModalOpen}
        transition={ModalTransition.BOTTOM_UP}
        modalClassName={classes.contactModal}
      >
        <div class="modal-header">
          <span onClick={props.closeModal} className="close spanClose">
            &times;
          </span>
        </div>
        <div className={classes.contactGrid}>
          <div>
            <img width="100%" height="200px" src={ContactUs} alt="" />

            <h2>Contact Us</h2>

            <div>
              <span style={{ fontSize: "20px" }}>XCCESS, LLC</span>
              <br />
              <span style={{ marginTop: 4, display: "inline-block" }}>
                312 Marshall Ave Suite #900
              </span>
              <br />
              <span style={{ marginTop: 4, display: "inline-block" }}>Laurel, MD 20708</span>
              <br />
              <br />
              <span style={{ marginTop: 4, display: "inline-block" }}>Phone: 1(877) 695-6953</span>
              <br />
              <br />
              <span style={{ marginTop: 4, display: "inline-block" }}>
                Email: support@xccess.com
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </ModalProvider>
  );
};

export default ContactUsModal;
