import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";
import Forum from "./components/Forum";
import Required from "./components/Required";

import { uploadFile, getUploadedFileUrl } from "utils/s3Bucket";

import { getRequest, patchRequest } from "utils/ApiServices";

import classes from "./Profile.module.css";

const Profile = () => {
  const [userData_, setUserData] = useState({
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    address: "",
    state: "",
    city: "",
    country: "",
    ssn: "",
    zipCode: "",
    payment: "",
    coupon: "",
    password: "",
    license: "",
    contract: "",
  });
  const [openLoader, setOpenLoader] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    const getUserRequest = async () => {
      const route = `/user/userData/${currentUser._id}`;
      try {
        const userData = await getRequest(route);
        setOpenLoader(false);
        setUserData(userData);
      } catch (err) {
        setOpenLoader(false);
      }
    };
    getUserRequest();
  }, []);

  const submitForm = (selectedFile) => {
    if (
      userData_.firstname !== "" &&
      userData_.lastname !== "" &&
      userData_.address !== "" &&
      userData_.zipCode !== "" &&
      userData_.state !== ""
    ) {
      setOpenLoader(true);

      if (selectedFile) {
        try {
          const myBucket = uploadFile(selectedFile);
          myBucket
            .on("error", (err) => {
              if (err) return Promise.reject(err);
            })
            .on("httpUploadProgress", async (evt) => {
              const progress = Math.round((evt.loaded / evt.total) * 100);
              if (progress === 100) {
                const temp = { ...userData_, license: getUploadedFileUrl(selectedFile) };
                postData(temp);
              }
            })
            .send();
        } catch (err) {
          toast.error("Something went wrong");
          setOpenLoader(false);
        }
      } else if (userData_.license) {
        return postData(userData_);
      }
    } else {
      toast.error("Please fill all the required * fields");
    }
  };

  const postData = async (payload) => {
    setOpenLoader(true);
    const route = "/user/editUserData";
    try {
      await patchRequest(route, payload);
      toast.success("Successfully Updated");
      setOpenLoader(false);
    } catch (err) {
      toast.error("Something went wrong");
      setOpenLoader(false);
    }
  };

  return (
    <div className={classes.background}>
      <Loader isOpen={openLoader} />
      <Container className={classes.container}>
        <Navbar />
        <div className={classes.contentBackground}>
          <div className={classes.wrapper}>
            <Grid container>
              <Grid item lg={3} md={3} xs={12}>
                <div className={classes.profile}>
                  <h2 className={classes.heading}>
                    {currentUser?.firstname} {currentUser?.lastname}
                  </h2>
                  <p>I.S.E.#{currentUser.coupon}</p>
                </div>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Forum data={userData_} setdata={setUserData} />
                <Required submitForm={submitForm} data={userData_} />
              </Grid>
              <Grid item lg={3} md={3} xs={12}></Grid>
            </Grid>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Profile;
