import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setCurrentUser } from "redux/actions";
import logoutHandler from "utils/logoutHandler";
import checkCurrentUserKeys from "utils/checkCurrentUserKeys";

function PublicUserAuth({ component: Component, ...restOfProps }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    if (currentUser) {
      if (checkCurrentUserKeys(currentUser)) {
        dispatch(setCurrentUser());
      } else {
        logoutHandler();
      }
    }
  }, []);

  return <Route {...restOfProps} render={(props) => <Component {...props} />} />;
}

export default PublicUserAuth;
