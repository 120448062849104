import React from "react";

import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";

import classes from "./TermsConditions.module.css";

const Nft = () => {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <Navbar />
        <div className={classes.contentBackground}>
          <div className={classes.contentContainer}>
            <h2>TERMS AND CONDITIONS</h2>
            <h3> March 2023 </h3>
            <p>
              Xccess, LLC request that you carefully review these Terms and Conditions before
              utilizing our website.
              <br />
              <br />
              <strong>
                The following TERMS and CONDITIONS are between Xccess, LLC, “the company” and “you”
                the “supporter.” Supporter means purchaser of our Reward Plan and/or Independent
                Representative.
              </strong>
              <br />
              <br />
              Visitors to MyXFund.com can purchase one of our Reward Plans to contribute to our
              mission to promote economic empowerment through our XCCESS portal and support
              developing our patented technology. In exchange for your support, the company will
              allocate a specific amount of XCCESS Cryptocurrency tokens for supporters which will
              be issued prior to the time the XCCESS token launch on cryptocurrency exchanges. The
              amount of XCCESS Cryptocurrency tokens issued will be based on the Reward Plan you
              purchase. In addition, supporters are required to be 18 years of age or older to
              participate.
              <br />
              <br />
              <strong>PURCHASING PROCESS:</strong>
              <br />
              <br />
              If you decide to purchase a Reward Plan, you must provide information to authenticate
              your identity including your full name, address, email, phone number without
              limitation, to your credit card number, and expiration date. XCCESS, LLC uses the
              highly reputable company, Stripe to securely process all credit/debit card
              transactions. Furthermore, you will be asked to create a password to log into your
              dashboard to view your allocated XCCESS Cryptocurrency tokens. The company limits
              supporters to purchasing up to three (3) Reward Plans. Also, for each Reward Plan, the
              company must receive the <strong>required information</strong> under the{" "}
              <strong>Taxation and Authenticating Identity</strong> section below to link each plan
              to you. Reward Plan #1 offers Supporters a Financial and Digital literacy eBook ($49
              value), Exclusive access to our Whitelist to be notified of future releases of XBlock
              Meta NFTs, Digital wallet onboarding and Basic Web3 Online Training ($300 value) and
              air drop bonuses for being an early supporter. Also, Supporters are eligible to
              participate in our referral program to earn commission.
              <br />
              <br />
              <strong>THE SUPPORTER INDEPENDENT REPRESENTATIVES’ DISCLOSURE:</strong>
              <br />
              <br />
              Supporters are eligible but{" "}
              <strong>
                <u>not</u>
              </strong>{" "}
              obligated to become an independent representative to earn referral commission and
              XCCESS Cryptocurrency bonuses for assisting the company promote its mission. As an
              independent representative, you agree to complete an independent contractor’s
              agreement and <strong>tax identifying</strong> form which includes providing your
              social security number so XCCESS, LLC can comply with Internal Revenue Service income
              reporting requirements. You agree XCCESS Crypto bonuses and commission can be withheld
              if your identifying tax information is not received within 15 days of becoming an
              independent representative. You also agree to adhere to ethical standards set forth in
              the independent contractor’s agreement and must not misrepresent XCCESS or XCCESS
              Cryptocurrency. Also, XCCESS, LLC reserves the right to terminate an independent
              representative without explanation.
              <br />
              <br />
              <strong>DISCLOSURE:</strong>
              <br />
              <br />
              All supporters understand and agree that XCCESS, LLC makes no claims or guarantees our
              XCCESS Cryptocurrency tokens have value or will increase in value. The cryptocurrency
              market is volatile, and you may gain, lose some or all your money in XCCESS
              Cryptocurrency tokens on the exchanges. XCCESS, LLC believes by implementing our core
              mission to reduce the economic gap through our viable business model, launching our
              patented technology and preserving the environment will garner global support and
              adoption of our XCCESS Cryptocurrency tokens by consumers and businesses. If the
              XCCESS Cryptocurrency increases on the exchanges, supporters should prepare to pay
              taxes. The XCCESS Cryptocurrency tokens are built on the carbon negative Algorand
              Blockchain. In addition, Supporters will receive their total amount of XCCESS
              Cryptocurrency tokens including bonuses prior to our launch on the Cryptocurrency
              exchanges.
              <br />
              <br />
              <strong>TAXATION AND AUTHENTICATING IDENTITY:</strong>
              <br />
              <br />
              Supporters understand and agree to authenticate his/her identity prior to being issued
              their XCCESS Cryptocurrency tokens. The company must request the supporter’s social
              security number, state driver’s license or state issued identification card. We may
              also need to capture a live digital image of you via a PC webcam or smartphone to
              authenticate your identity. XCCESS, LLC. is committed to complying with Know Your
              Customer (KYC). Therefore, if the supporter does not comply with this requirement, the
              company reserves the right to withhold issuing the supporter’s XCCESS Cryptocurrency
              tokens until their identity can be authenticated. The company must authenticate the
              Supporter identity to be compliant with governmental regulations and tax reporting
              laws.
              <br />
              <br />
              <strong>CHANGES TO TERMS & CONDITIONS:</strong>
              <br />
              <br />
              XCCESS, LLC reserves the right, at the company’s sole discretion, to modify or replace
              these Terms and Conditions at any time without the pre-approval of supporters, and
              future members, or subscribers. This includes Reward Plan offerings, XCCESS
              Cryptocurrency token bonuses, and Referral Commission. If a revision to these Terms
              and Conditions are made supporters will be notified upon login and/or via email.
              <br />
              <br />
              <strong>DISCLAIMERS: </strong>
              <br />
              <br />
              XCCESS, LLC will do its best to prevent all service failures to our websites and
              mobile applications. The company will strive to prevent disruptions when distributing
              our XCCESS Cryptocurrency tokens via the blockchain platform the tokens were built
              upon. XCCESS, LLC makes no promises or guarantees our websites, cryptocurrency
              blockchain platform and mobile apps will always be error-free due to technical issues
              which may arise. XCCESS, LLC assumes no responsibility or liability for unforeseen
              disruptions and/or losses caused by reputable third-party solutions which may
              interface with our website and/or mobile app. We do not have control over third-party
              websites and assume no responsibility for their content, privacy policy, term and
              conditions and functionality.
              <br />
              <br />
              <strong>INDEMNIFY:</strong>
              <br />
              <br />
              Supporters understand and agree to indemnify, defend, and hold harmless XCCESS, LLC,
              the parent company, Remote Xccess, LLC, affiliates, officers, equity partners,
              shareholders, directors, employees, licensors, agents, and independent contractors,
              from and against any and all liabilities, complaints, claims, damages and losses and
              expenses (including attorney’s fees) due to unforeseen circumstances which may arise
              in the pursuit of the company executing the launch of our XCCESS Cryptocurrency
              tokens. The supporters and users of our internet properties, web apps and devices
              acknowledge and indemnify XCCESS, LLC and its parent company from any liability
              directly or indirectly, for loss caused or alleged from any third-party solution or
              link.
              <br />
              <br />
              <strong>SEVERABILITY:</strong>
              <br />
              <br />
              In the case where any provision in these Terms and Conditions is found to be
              unenforceable, then that provision will be severed from these Terms and Conditions,
              but it will not invalidate the enforceability of all remaining provisions.
              <br />
              <br />
              <strong>ARBITRATION AGREEMENT:</strong>
              <br />
              <br />
              Supporters understand and agree to resolve legal claims, disputes arising out of these
              Terms and Conditions and XCCESS Cryptocurrency tokens by using an arbitration service.
              Furthermore, XCCESS, LLC reserves the right to select a reputable and neutral
              arbitrating association to govern the arbitration process, record the binding decision
              and enforce the dispute-resolution.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Nft;
