import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";

import Loader from "components/Loader/Loader";

import { getRequest } from "utils/ApiServices";

import XccessLogo from "Assets/xccess-logo.png";
import classes from "./RequestNewCode.module.css";

const RequestNewCode = () => {
  const { email } = useParams();
  const [newCodeResponse, setNewCodeResponse] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");

  useEffect(() => {
    setOpenLoader(true);
    const requestNewCodeAPI = async () => {
      const route = `/user/email/${email}`;
      try {
        const respData = await getRequest(route);
        setNewCodeResponse(respData.msg);
        setResponseStatus("success");
        setOpenLoader(false);
      } catch (err) {
        setOpenLoader(false);
        setNewCodeResponse(err.response.data.errmsg);
        setResponseStatus("failure");
      }
    };
    requestNewCodeAPI();
  }, []);

  return (
    <div>
      <Loader isOpen={openLoader} />
      <div className={classes.container}>
        <div style={{ textAlign: "center" }}>
          <img src={XccessLogo} width="330px" alt="" />
          <br />
          {responseStatus === "success" && (
            <CheckCircleTwoToneIcon style={{ color: "#52C41A", fontSize: "80px" }} />
          )}
          {responseStatus === "failure" && (
            <InfoTwoToneIcon style={{ color: "#FAAD14", fontSize: "80px" }} />
          )}
          <h3>{newCodeResponse}</h3>
        </div>
      </div>
    </div>
  );
};

export default RequestNewCode;
