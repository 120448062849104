import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Container from "@material-ui/core/Container";

import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import Loader from "components/Loader/Loader";
import BonusTable from "./components/BonusTable";
import LicenceCard from "./components/LicenceCard";
import PaymentMethodCard from "./components/PaymentMethodCard";

import { getRequest } from "utils/ApiServices";

import classes from "./EditBonus.module.css";
import WalletAddressCard from "./components/WalletAddressCard";

const EditBonus = () => {
  let { userId } = useParams();
  let history = useHistory();
  const [referalData, setReferalData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [userTypeIs, setUserType] = useState("");

  useEffect(() => {
    getMe();
    getUser();
    getUserBonus();
  }, []);

  const getUser = async () => {
    setOpenLoader(true);
    const route = `/user/userData/${userId}`;
    try {
      const userData = await getRequest(route);
      setOpenLoader(false);
      setUserData(userData);
    } catch (err) {
      setOpenLoader(false);
    }
  };

  const getUserBonus = async () => {
    setOpenLoader(true);
    const route = `/user/getReferal?id=${userId}`;
    try {
      const referals = await getRequest(route);
      setReferalData(referals);
      setOpenLoader(false);
    } catch (err) {
      setOpenLoader(false);
    }
  };

  const getMe = async () => {
    setOpenLoader(true);
    const route = "/user/me";
    try {
      const currentUser = await getRequest(route);
      setOpenLoader(false);
      setUserType(currentUser.usertype);
      if (["ceo", "super-admin", "admin"].includes(currentUser.usertype)) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
        history.push("/");
      }
    } catch (err) {
      setOpenLoader(false);
    }
  };

  return (
    <>
      <Loader isOpen={openLoader} />
      {isAdmin ? (
        <div className={classes.main}>
          <Container className={classes.container}>
            <Navbar />
            <div className={classes.height}>
              <BonusTable
                data={referalData}
                userData={userData}
                userTypeIs={userTypeIs}
                getUserBonus={getUserBonus}
              />
            </div>

            <div className={classes.mainCards}>
              <h2>Downloads</h2>
              <div className={classes.container}>
                <div className={classes.mainCardsFlex}>
                  <LicenceCard data={userData?.license} cardName="License" />
                  <LicenceCard data={userData?.contract} cardName="Contract" />
                  <PaymentMethodCard userData={userData} />
                  <WalletAddressCard userData={userData} />
                </div>
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      ) : null}
    </>
  );
};
export default EditBonus;
