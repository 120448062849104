import React from "react";

import Grid from "@material-ui/core/Grid";

import LockIcon from "Assets/lock-icon.png";
import classes from "./Forum.module.css";

const Forum = ({ data, setdata }) => {
  return (
    <div>
      <form className={classes.formInline} action="/action_page.php">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.center}>
              <span>
                <img width="18px" src={LockIcon} alt="lock" />
              </span>
              <span className={classes.heading1}> REQUIRED INFORMATION</span>
            </div>
            <div className={classes.flex2}>
              <div>
                {" "}
                <label for="myInput">First Name*:</label>
                <input
                  className={classes.largeInput}
                  value={data.firstname}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      firstname: e.target.value,
                    })
                  }
                  type="text"
                  id="myInput"
                />
              </div>
              <div>
                {" "}
                <label for="myInput">Last Name*:</label>
                <input
                  className={classes.largeInput}
                  value={data.lastname}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      lastname: e.target.value,
                    })
                  }
                  type="text"
                  id="myInput"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.flex}>
              <label for="myInput">Current Address*:</label>
              <input
                className={classes.largeInput}
                value={data.address}
                onChange={(e) =>
                  setdata({
                    ...data,
                    address: e.target.value,
                  })
                }
                type="text"
                id="myInput"
              />
            </div>
          </Grid>
          <Grid item lg={7} md={12} sm={12} xs={12}>
            <div className={classes.flex}>
              <label for="myInput">City:</label>
              <input
                className={classes.largeInput}
                value={data.city}
                onChange={(e) =>
                  setdata({
                    ...data,
                    city: e.target.value,
                  })
                }
                type="text"
                id="myInput"
              />
            </div>
          </Grid>
          <Grid item lg={5} md={12} sm={12} xs={12}>
            <div className={classes.flex}>
              <label for="myInput">
                <nobr>Zip Code*:</nobr>
              </label>
              <input
                className={classes.largeInput}
                value={data.zipCode}
                onChange={(e) =>
                  setdata({
                    ...data,
                    zipCode: e.target.value,
                  })
                }
                type="text"
                id="myInput"
              />
            </div>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <div className={classes.flex}>
              <label for="myInput">State*:</label>
              <input
                className={classes.largeInput}
                value={data.state}
                onChange={(e) =>
                  setdata({
                    ...data,
                    state: e.target.value,
                  })
                }
                type="text"
                id="myInput"
              />
            </div>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <div className={classes.flex}>
              <label for="myInput">Country:</label>
              <input
                className={classes.largeInput}
                value={data.country}
                onChange={(e) =>
                  setdata({
                    ...data,
                    country: e.target.value,
                  })
                }
                type="text"
                id="myInput"
              />
            </div>
          </Grid>
          <Grid item lg={9} md={12} sm={12} xs={12}>
            <div className={classes.center}>
              <span>
                <img width="18px" src={LockIcon} alt="lock" />
              </span>
              <span className={classes.taxText}> FOR TAX REPORTING PURPOSES</span>
            </div>
            <div className={classes.flex}>
              <label for="myInput">Social Security Number:</label>
              <input
                className={classes.largeInput}
                style={{ width: "" }}
                value={data.ssn}
                onChange={(e) =>
                  setdata({
                    ...data,
                    ssn: e.target.value,
                  })
                }
                type="text"
                id="myInput"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <p className={classes.note}>
                <span>NOTE:</span> The above information is stored in a highly secure database for
                tax reporting purposes.
              </p>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default Forum;
