import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import classes from "../Checkout.module.css";

const tooltipText = (
  <ul className={classes.list}>
    <li>Length should be at least 8 characters</li>
    <li>Should contain at least one upper case letter</li>
    <li>Should contain at least one lower case letter</li>
    <li>Should contain at least one special character</li>
    <li>Should contain at least one digit</li>
  </ul>
);

const RequiredInfo = forwardRef(({ state, handleInformationChange, emailExists, tac }, ref) => {
  const [isValid, setIsValid] = useState(false);

  useImperativeHandle(ref, () => ({
    onFormSubmit() {
      setIsValid(true);
    },
  }));
  const handleChange = (evt) => {
    setIsValid(false);
    let dupState = state;
    if (evt.target.name === "termsandcondition") {
      handleInformationChange(evt.target.checked, "tac");
    } else {
      dupState = {
        ...state,
        [evt.target.name]: evt.target.value,
      };
      handleInformationChange(dupState, "info");
    }

    // setState({ ...state, [evt.target.name]: evt.target.value });
  };
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Required Information
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={state.firstName === "" && isValid ? true : false}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            value={state.firstName}
            onChange={handleChange}
            helperText={state.firstName === "" && isValid ? "First Name is required." : null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={state.lastName === "" && isValid ? true : false}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            value={state.lastName}
            onChange={handleChange}
            helperText={state.lastName === "" && isValid ? "Last Name is required." : null}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={state.address === "" && isValid ? true : false}
            id="address"
            name="address"
            label="Address"
            fullWidth
            autoComplete="shipping address"
            value={state.address}
            onChange={handleChange}
            helperText={state.address === "" && isValid ? "Address is required." : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={state.state === "" && isValid ? true : false}
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            value={state.state}
            onChange={handleChange}
            helperText={state.state === "" && isValid ? "State is required." : null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={state.zip === "" && isValid ? true : false}
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            value={state.zip}
            onChange={handleChange}
            helperText={state.zip === "" && isValid ? "Zip is required." : null}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="phone"
            name="phone"
            label="Phone"
            fullWidth
            autoComplete="shipping Phone"
            value={state.phone}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={
              state.email === "" && isValid
                ? true
                : !validateEmail(state.email) && state.email.length > 0
                ? true
                : emailExists
                ? true
                : false
            }
            id="email"
            name="email"
            label="Email"
            type="email"
            fullWidth
            // autoComplete="email"
            value={state.email}
            onChange={handleChange}
            helperText={
              state.email === "" && isValid
                ? "Email is required."
                : !validateEmail(state.email) && state.email.length > 0
                ? "Email is not valid"
                : emailExists
                ? "Email already exist"
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={
              state.confirmemail === "" && isValid
                ? true
                : state.confirmemail.toLowerCase() !== state.email.toLowerCase() &&
                  state.confirmemail.length > 0
                ? true
                : false
            }
            id="confirmemail"
            name="confirmemail"
            label="Confirm Email"
            type="email"
            fullWidth
            // autoComplete="confirmemail"
            value={state.confirmemail}
            onChange={handleChange}
            helperText={
              state.email === "" && isValid
                ? "Email is required."
                : state.confirmemail.toLowerCase() !== state.email.toLowerCase() &&
                  state.confirmemail.length > 0
                ? "Confirm email does not match."
                : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={
              (state.password === "" && isValid) || (state.password.length < 8 && isValid)
                ? true
                : false
            }
            id="password"
            name="password"
            label="password"
            type="password"
            fullWidth
            autoComplete="password"
            value={state.password}
            onChange={handleChange}
            helperText={
              state.password === "" && isValid
                ? "Password is required."
                : state.password.length < 8 && isValid
                ? "Please enter at least eight character"
                : null
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Tooltip title={tooltipText}>
            <Button size="small" style={{ backgroundColor: "#fc4c4c", color: "white" }}>
              Password Requirements?
            </Button>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="termsandcondition"
                checked={tac}
                onChange={handleChange}
              />
            }
            label={
              <span>
                By checking box you agree to the{" "}
                <Link to="/terms-conditions" className={classes.tc}>
                  Terms
                </Link>{" "}
                and{" "}
                <Link to="/terms-conditions" className={classes.tc}>
                  Conditions
                </Link>{" "}
                set forth in this link. Terms and Conditions
              </span>
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
export default RequiredInfo;
