import React, { useState, forwardRef, useImperativeHandle } from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import PackageCard from "./PackageCards";

const PaymentForm = forwardRef(
  ({ state, selectedValue, handlePaymentChange, onPackageChangeHandler }, ref) => {
    const [isValid, setIsValid] = useState(false);

    useImperativeHandle(ref, () => ({
      onFormSubmit() {
        setIsValid(true);
      },
    }));
    const handleChange = (evt) => {
      setIsValid(false);
      let dupState = state;
      const re = /[0-9]+/g;
      if (evt.target.value === "" || re.test(evt.target.value)) {
        dupState = {
          ...state,
          [evt.target.name]: evt.target.value,
        };
        handlePaymentChange(dupState);
      }
    };
    const onPackageSelect = (val, r, t, tt) => {
      onPackageChangeHandler(val, r, t, tt);
    };
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Payment method
        </Typography>
        <div>
          <PackageCard
            selectedValue={selectedValue}
            onPackageSelect={(val, r, t, tt) => onPackageSelect(val, r, t, tt)}
          />
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              error={state.cardNumber === "" && isValid ? true : false}
              helperText={state.cardNumber === "" && isValid ? "Card Number is required." : null}
              id="cardNumber"
              label="Card number"
              fullWidth
              autoComplete="cc-number"
              placeholder="Valid Card Number"
              name="cardNumber"
              inputProps={{ maxLength: 18 }}
              value={state.cardNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              error={state.cvv === "" && isValid ? true : false}
              helperText={state.cvv === "" && isValid ? "Card CVV is required." : null}
              id="cvv"
              label="CVV"
              fullWidth
              autoComplete="cc-csc"
              placeholder="CVV"
              name="cvv"
              inputProps={{ maxLength: 3 }}
              value={state.cvv}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              error={state.expMonth === "" && isValid ? true : false}
              helperText={
                state.expMonth === "" && isValid ? "Card Expiry Month is required." : null
              }
              id="expMonth"
              label="Expiry month"
              fullWidth
              autoComplete="cc-exp"
              // placeholder="Expiry Month"
              inputProps={{ maxLength: 2 }}
              value={state.expMonth}
              name="expMonth"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              error={state.expYear === "" && isValid ? true : false}
              helperText={state.expYear === "" && isValid ? "Card Expiry Year is required." : null}
              id="expYear"
              label="Expiry year"
              fullWidth
              autoComplete="cc-exp"
              inputProps={{ maxLength: 2 }}
              name="expYear"
              value={state.expYear}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
);
export default PaymentForm;
