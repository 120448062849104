// const linkData = (
//   <p className={classes.accordText}>
//     {" "}
//     Ans. Please visit XCCESS.com for more information about our company or{" "}
//     <Link to="/team">click here to see our Core Team.</Link>
//   </p>
// );
export let faqData = [
  {
    question:
      "My confirmation email and resend code button is not working when I click on it from my email.",
    answer:
      "Ans. Return to MyXFund.com to login, click Forgot Password to re-enter it and check email for code then return to enter code before it expires. If code expires, just try the process again.",
  },
  {
    question: "How do I see and track my XCCESS (XCS) Cryptocurrency?",
    answer:
      "Ans. After becoming a supporter, you will receive an email with details about accessing your dashboard to see your XCCESS Cryptocurrency balance.  Once we officially launch on the exchange your balance will be sent to your digital wallet to hold, trade, or sell. ",
  },
  {
    question: "When is the official launch for XCCESS (XCS) Cryptocurrency on the exchanges?",
    answer: "Ans. We anticipate launching on the Crypto Exchanges by Oct 2024 or earlier.",
  },
  {
    question:
      "Can I purchase more XCCESS (XCS) Cryptocurrency before the tokens are made available on exchanges?",
    answer:
      "Ans. Up to 3 Reward Plans can be purchased by the same Supporter, however the Supporter must validate their identity by completing the required information in their dashboard for each Reward Plan he/she purchases.",
  },
  {
    question: "What is the XCS symbol for?",
    answer:
      "Ans. XCS is our official trading symbol for the exchanges.  Example:  Bitcoin’s symbol is BTC.",
  },
  {
    question:
      "Does your company have patents which can strengthen your competitive position within the tech space?",
    answer:
      "Ans. We own 5 patents relating cloud computing wearable technology and Augmented Reality (AR) eyewear.",
  },
  {
    question: "Will your company be hiring and will you have remote worker positions?",
    answer:
      "Ans. We plan to hire soon and prioritize applicants who are our supporters.   Majority of our future positions will be done by remote workers.",
  },
  {
    question:
      "Where is my email confirmation to access my Supporter Dashboard? I did not see it in my email inbox.",
    answer:
      "Ans. Please check your Junk or Spam email folders for this email. If you still do not see it, email support@xccess.com",
  },
  {
    question: "Where I can find out additional information regarding XCCESS, LLC and your team?",
    answer: "Please visit XCCESS.com for more information about our company and core team",
  },
  {
    question: "Do you have a Refund Policy?",
    answer:
      "Ans. Yes, we offer a 30-Day refund policy if submitted in writing via your registered email on record within 30 days of purchase.",
  },
];
