import React from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import classes from "./VideoDetails.module.css";

import Button from "@material-ui/core/Button";

import FacebookIcon from "Assets/facebook-icon.jpg";
import TwitterIcon from "Assets/twitter-icon.jpg";

const VideoDetails = () => {
  return (
    <div className={classes.courseDetailsContainer}>
      <div>
        <Button variant="contained" color="secondary">
          Share Video
        </Button>
        <br />
        <br />
        <div className={classes.socialDiv}>
          <FacebookShareButton url={"https://www.youtube.com/watch?v=lJ0Htru_xqY"}>
            <img src={FacebookIcon} alt="" />
          </FacebookShareButton>
          &nbsp;&nbsp;
          <TwitterShareButton url={"https://www.youtube.com/watch?v=lJ0Htru_xqY"}>
            <img src={TwitterIcon} alt="" />
          </TwitterShareButton>
        </div>

        <iframe
          className={classes.videoSize}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/ome_ZUOo8Jw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoDetails;
