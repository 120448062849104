import React, { useState, useEffect } from "react";
import "react-simple-hook-modal/dist/styles.css";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";

import arrowDown from "Assets/arrow-down.png";
import classes from "./FAQs.module.css";

import { faqData } from "./data";

const FAQs = () => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    if (window.scrollY > 1659) {
      let selected = document.querySelectorAll(`.${classes.faqDiv2}`);
      selected.forEach((element, i) => {
        setTimeout(() => {
          element.classList.add(classes.scrollClass);
        }, 100 * i);
      });
    }
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const scrollHandler = (e) => {
    if (window.scrollY > 1659) {
      let selected = document.querySelectorAll(`.${classes.faqDiv2}`);
      selected.forEach((element, i) => {
        setTimeout(() => {
          element.classList.add(classes.scrollClass);
        }, 100 * i);
      });
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.heading}>
          <span>Frequently Asked Questions</span>
        </div>

        <div className={classes.contentDiv}>
          <div>
            {faqData.map((fd, i) => (
              <Accordion
                expanded={expanded === i}
                onChange={handleChange(i)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<img src={arrowDown} alt="arrowDown" width="20px" height="15px" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.accordHeading}>{fd.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {i === faqData.length - 1 ? (
                    fd.answer
                  ) : (
                    <p className={classes.accordText}>{fd.answer}</p>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
