import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";
import ForgetPassword from "./ForgetPassword";

import { postRequest } from "utils/ApiServices";
import { parseError } from "utils/helpers";

import XccessLogo from "Assets/xccess-logo.png";
import "./modal.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  dlButton: {
    border: " 2px solid black",
    borderRadius: "6px",
    backgroundColor: "white",
  },
}));

const Login = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const user = {
      email: email,
      password: password,
    };
    setOpenLoader(true);

    const route = "/user/login";
    try {
      const apiResp = await postRequest(route, user);
      setOpenLoader(false);
      if (apiResp) {
        toast.success("Login Successfully");
        localStorage.setItem("user", JSON.stringify(apiResp));
        if (["ceo", "super-admin", "admin"].includes(apiResp.usertype)) {
          history.push("/admin");
        } else if (apiResp.payment.reward === "Reward #1") {
          history.push("/rew#1");
        } else if (apiResp.payment.reward === "Reward #2") {
          history.push("/rew#2");
        } else if (apiResp.payment.reward === "Reward #3") {
          history.push("/rew#3");
        } else if (apiResp.payment.reward === "Reward #4") {
          history.push("/rew#4");
        }
      }
    } catch (err) {
      setOpenLoader(false);
      if (err) {
        toast.error(parseError(err));
      }
    }
  };
  return (
    <div>
      <Loader isOpen={openLoader} />
      <Button
        id="login"
        variant="outlined"
        className="dlButton"
        onClick={handleOpen}
        size={props.from === "mobile" ? "small" : "medium"}
      >
        Login
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div>
            <section id="content">
              <div class="modal-header">
                <span onClick={handleCloseModal} class="close spanClose">
                  &times;
                </span>
              </div>
              <div class="xccess-logo">
                <img src={XccessLogo} width="100px" alt="" />
              </div>
              <form action="" onSubmit={onSubmitHandler}>
                <h1>Login</h1>
                <div>
                  <input
                    type="text"
                    placeholder="Email"
                    required=""
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="Password"
                    required=""
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <input type="submit" value="Log in" />

                  <ForgetPassword />
                </div>
              </form>
            </section>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Login;
