import React from "react";
import "react-simple-hook-modal/dist/styles.css";

import Navbar from "components/Navbar/Navbar";
import CheckoutModal from "./CheckoutModal";

import ListBulletIcon from "Assets/list-bullet-icon.png";
import XccessLogo from "Assets/xccess-logo.png";
import XRaceCarsLogo from "Assets/xrace-cars-logo.png";
import classes from "./Hero.module.css";

const cardsData = [
  {
    reward: "Reward Plan #1",
    price: "$99",
    texts: [
      <p>Basic Web3 & Digital Wallet Training</p>,
      <p>Financial and Digital Literacy eBook</p>,
      <p>
        500 Allocated XCCESS Tokens <br />
        (15,000 with{" "}
        <span className={classes.coloredValue}>
          30<span>Xs</span>
        </span>{" "}
        Promo)
      </p>,
      <p>
        Exclusive Whitelist Access to future{" "}
        <a
          href="https://xblockmeta.com/"
          target="_blank"
          rel="noreferrer"
          className={classes.xBlockLink}
        >
          XRACECARS
        </a>{" "}
        and{" "}
        <a
          href="https://xblockmeta.com/"
          target="_blank"
          rel="noreferrer"
          className={classes.xBlockLink}
        >
          XBlock Meta
        </a>{" "}
        NFTs.
      </p>,
      <p>Digital Wallet Air Drops</p>,
    ],
  },
];

const Hero = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className={classes.heroContainer}>
      <div className={classes.mainDiv}>
        <Navbar />

        <div className={classes.contentDiv}>
          <div className={classes.rewardHeaderDiv}>
            <div className={classes.logoDiv}>
              <img src={XccessLogo} alt="XccessLogo" />
              <p>
                30<span>Xs Promo</span>
              </p>
            </div>
            <div className={classes.rewardText}>
              <p>Reward Plan</p>
            </div>
          </div>

          <div className={classes.cardsContainer}>
            {cardsData.map((card, index) => (
              <div key={index} className={classes.rewardPlanCard}>
                <div className={classes.rewardButton}>
                  <p>{card.reward}</p>
                </div>

                <p className={classes.rewardPrice}>{card.price}</p>

                {card?.texts.map((text, index) => (
                  <div key={index} className={classes.listSinglePoint}>
                    <div>
                      <img src={ListBulletIcon} alt="box" />
                    </div>
                    <p style={{ flexGrow: 1 }}>{text}</p>
                  </div>
                ))}

                <div className={classes.earlySupporterButton}>
                  <button onClick={() => setOpen(true)}>Become An Early Supporter!</button>
                </div>

                <div
                  className={`${classes.rewardButton} ${classes.buyButton}`}
                  style={{ marginTop: "auto" }}
                  onClick={() => setOpen(true)}
                >
                  <p>Buy Now</p>
                </div>
                <CheckoutModal open={open} setOpen={setOpen} />
              </div>
            ))}

            <div className={classes.heroVideo}>
              <iframe
                className={classes.videoSize}
                width="620"
                height="350"
                src="https://www.youtube.com/embed/S9SmCXPT5eA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div className={classes.xracecars}>
            <p>
              The $999 Reward Plan coming upon the NFT release of our{" "}
              <img src={XRaceCarsLogo} alt="" />{" "}
            </p>
          </div>

          <div className={classes.bottomNote}>
            <p>
              After purchase, login to dashboard to learn how to earn up to 6 figures from our a
              generous referral program.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
