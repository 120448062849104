import React from "react";
import classes from "./CourseDetails.module.css";

import FinancialLiteracyBook from "Assets/financial-literacy-book.png";

const CourseDetails = () => {
  return (
    <div className={classes.courseDetailsContainer}>
      <div>
        <p className={classes.courseTitle}>FINANCIAL LITERACY</p>
        <a
          href="/FINANCIAL_AND_DIGITAL_LITERACY_EBOOK.pdf"
          download="FINANCIAL_AND_DIGITAL_LITERACY_EBOOK.pdf"
          className={classes.downloadAnchor}
        >
          <p className={classes.downloadButton}>
            Click to Download Financial and Digital Literacy eBook
          </p>
        </a>
        <img src={FinancialLiteracyBook} width="80%" alt="Financial literacy course" />
      </div>
    </div>
  );
};

export default CourseDetails;
