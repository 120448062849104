import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Container from "@material-ui/core/Container";

import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";
import AdminTable from "./components/AdminTable";
import EmbedVideo from "./components/EmbedVideo";

import { getRequest, patchRequest } from "utils/ApiServices";

import classes from "./Dashboard.module.css";

const Dashboard = () => {
  const [embedData, setEmbedData] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    getEmbedVideo();
  }, []);

  const getEmbedVideo = async () => {
    setOpenLoader(true);
    const route = "/admin/getUrl";
    try {
      const videoURL = await getRequest(route);
      setOpenLoader(false);
      setEmbedData(videoURL);
    } catch (err) {
      setOpenLoader(false);
    }
  };

  const updateEmbedVideo = async (data) => {
    setOpenLoader(true);
    const route = "/admin/updateVideoUrl";
    const payload = {
      video: data,
    };
    try {
      await patchRequest(route, payload);
      setOpenLoader(false);
      toast.success("Successfully Updated");
      getEmbedVideo();
    } catch (err) {
      setOpenLoader(false);
      toast.error("Something went wrong ");
    }
  };

  return (
    <>
      <Loader isOpen={openLoader} />
      {["ceo", "super-admin", "admin"].includes(currentUser?.usertype) ? (
        <div className={classes.main}>
          <Container className={classes.container}>
            <Navbar />
            {/* <div>
              <EmbedVideo data={embedData} update={(data) => updateEmbedVideo(data)} />
            </div> */}
            <div className={classes.height}>
              <AdminTable />
            </div>
          </Container>
          <Footer />
        </div>
      ) : null}
    </>
  );
};
export default Dashboard;
