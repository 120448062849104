import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import Leftbar from "./components/Leftbar/Leftbar";
import Totalbox from "./components/Totalbox/Totalbox";
import Comingsoon from "./components/ComingSoon/ComingSoon";

import classes from "./PaidSubDash.module.css";

const PaidSubDash = () => {
  // 70px is Navbar Height. 50px is Footer Height
  const wrapperStyle = {
    textAlign: "center",
    backgroundColor: "black",
    minHeight: `calc(100vh - 70px - 50px)`,
    display: "flex",
  };
  return (
    <>
      <div className={classes.background}>
        <Container className={classes.container}>
          <Navbar />
          <div style={wrapperStyle}>
            <Grid container>
              <Grid item md={4} xs={12}>
                <Leftbar />
              </Grid>
              <Grid item md={4} xs={12}>
                <Totalbox />
              </Grid>
              <Grid item md={4} xs={12}>
                <Comingsoon />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PaidSubDash;
