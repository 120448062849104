import { combineReducers } from "redux";
import counterReducer from "./counterReducer";
import currentUserReducer from "./currentUserReducer";

const rootReducer = combineReducers({
  counter: counterReducer,
  currentUser: currentUserReducer,
});

export default rootReducer;
