import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";

import { patchRequest } from "utils/ApiServices";
import { parseError } from "utils/helpers";

import classes2 from "../Dashboard.module.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
}));

const EditModal = ({ userData, getUserRequest }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isValid, setIsValid] = useState(false);
  const [editForm, setEditForm] = useState({
    id: userData._id,
    firstname: userData.firstname,
    lastname: userData.lastname,
    address: userData.address,
    phone: userData.phone,
    coupon: userData.coupon,
    supporterCoupon: userData.supporter?.coupon,
    payment: {
      status: true,
      amount: userData.payment.amount,
      reward: userData.payment.reward,
      text: userData.payment.text,
      tokens: userData.payment.tokens,
    },
    createdAt: new Date(),
  });
  const [openLoader, setOpenLoader] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (evt) => {
    setIsValid(false);
    if (evt.target.name === "firstName") {
      setEditForm({
        ...editForm,
        firstname: evt.target.value,
      });
    } else if (evt.target.name === "lastName") {
      setEditForm({
        ...editForm,
        lastname: evt.target.value,
      });
    } else if (evt.target.name === "address") {
      setEditForm({
        ...editForm,
        address: evt.target.value,
      });
    } else if (evt.target.name === "code") {
      setEditForm({
        ...editForm,
        coupon: evt.target.value,
      });
    } else if (evt.target.name === "supporterCoupon") {
      setEditForm({
        ...editForm,
        supporterCoupon: evt.target.value,
      });
    } else if (evt.target.name === "reward") {
      setEditForm({
        ...editForm,
        payment: {
          ...editForm.payment,
          reward: evt.target.value,
        },
      });
    } else if (evt.target.name === "text") {
      setEditForm({
        ...editForm,
        payment: {
          ...editForm.payment,
          text: evt.target.value,
        },
      });
    } else if (evt.target.name === "phone") {
      setEditForm({
        ...editForm,
        phone: evt.target.value,
      });
    } else if (evt.target.name === "tokens") {
      setEditForm({
        ...editForm,
        payment: {
          ...editForm.payment,
          tokens: evt.target.value,
        },
      });
    }
  };
  const onSubmit = async () => {
    setIsValid(true);
    if (
      editForm.firstname === "" ||
      editForm.lastname === "" ||
      editForm.address === "" ||
      editForm.coupon === "" ||
      editForm.payment.reward === "" ||
      editForm.payment.text === "" ||
      editForm.payment.tokens === ""
    ) {
      toast.error("Please fill out the form");
    } else {
      setOpenLoader(true);

      const route = "/user/edit";
      try {
        await patchRequest(route, editForm);
        setOpenLoader(false);
        toast.success("User Information edited successfully.");
        getUserRequest();
      } catch (err) {
        setOpenLoader(false);
        if (err.response) {
          toast.error(parseError(err));
        }
      }
    }
  };

  const formatDate = (dt) => {
    let date = new Date(dt).toLocaleDateString();
    let time = new Date(dt).toLocaleTimeString();
    return date + "  -  " + time;
  };

  return (
    <div>
      <Loader isOpen={openLoader} />
      <Button variant="contained" size="small" color="secondary" onClick={handleOpen}>
        Edit
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Edit User
            </Typography>
            <div className={classes.fpForm}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    error={editForm.firstname === "" && isValid ? true : false}
                    id="firstName"
                    name="firstName"
                    label="First name"
                    fullWidth
                    autoComplete="given-name"
                    value={editForm.firstname}
                    onChange={handleChange}
                    helperText={
                      editForm.firstname === "" && isValid ? "First Name is required." : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    error={editForm.lastname === "" && isValid ? true : false}
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    fullWidth
                    autoComplete="family-name"
                    value={editForm.lastname}
                    onChange={handleChange}
                    helperText={
                      editForm.lastname === "" && isValid ? "Last Name is required." : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={editForm.address === "" && isValid ? true : false}
                    id="address"
                    name="address"
                    label="Address"
                    fullWidth
                    autoComplete="shipping address"
                    value={editForm.address}
                    onChange={handleChange}
                    helperText={editForm.address === "" && isValid ? "Address is required." : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="phone"
                    name="phone"
                    label="Phone"
                    fullWidth
                    autoComplete="shipping Phone"
                    value={editForm.phone}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    error={editForm.payment.tokens === "" && isValid ? true : false}
                    id="tokens"
                    name="tokens"
                    label="Tokens"
                    type="tokens"
                    fullWidth
                    value={editForm.payment.tokens}
                    onChange={handleChange}
                    helperText={
                      editForm.payment.tokens === "" && isValid ? "token is required." : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    error={editForm.payment.text === "" && isValid ? true : false}
                    id="text"
                    name="text"
                    label="Text"
                    type="text"
                    fullWidth
                    value={editForm.payment.text}
                    onChange={handleChange}
                    helperText={
                      editForm.payment.text === "" && isValid ? "text is required." : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    error={editForm.payment.reward === "" && isValid ? true : false}
                    id="reward"
                    name="reward"
                    label="Reward"
                    type="text"
                    fullWidth
                    value={editForm.payment.reward}
                    onChange={handleChange}
                    helperText={
                      editForm.payment.reward === "" && isValid ? "reward is required." : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="amount"
                    name="amount"
                    label="Amount"
                    type="text"
                    fullWidth
                    value={userData.payment.amount}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="modifiedBy"
                    name="modifiedBy"
                    label="Modified By"
                    type="text"
                    fullWidth
                    value={userData.modifiedBy?.email}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    error={editForm.coupon === "" && isValid ? true : false}
                    id="code"
                    name="code"
                    label="Code"
                    type="text"
                    fullWidth
                    value={editForm.coupon}
                    onChange={handleChange}
                    helperText={editForm.coupon === "" && isValid ? "coupon is required." : null}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="supporterCoupon"
                    name="supporterCoupon"
                    label="Referrer's Code"
                    type="text"
                    disabled
                    fullWidth
                    value={editForm.supporterCoupon}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="modifiedAt"
                    name="modifiedAt"
                    label="Modified At"
                    type="text"
                    fullWidth
                    value={formatDate(userData.modifiedAt)}
                    disabled
                  />
                </Grid>

                <Grid item xs={12}>
                  <div className={classes2.editFormFlex}>
                    <div>
                      <Button variant="contained" color="primary" onClick={onSubmit}>
                        Edit
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        // color="primary"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default EditModal;
