import React from "react";

import ConferenceCall from "Assets/conference-call.jpg";

import classes from "./TrainingContent.module.css";

const TrainingContent = () => {
  return (
    <div className={classes.TrainingContentContainer}>
      <h2>Web3.0 Onboard Training</h2>

      <img className={classes.conferenceImg} src={ConferenceCall} alt="ConferenceCall" />

      <p>Check email to schedule training</p>
    </div>
  );
};

export default TrainingContent;
