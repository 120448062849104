import React, { useState, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";
import RequiredInfo from "./components/RequiredInfo";
import PaymentForm from "./components/PaymentForm";
import Review from "./components/Review";

import { commissionCheck } from "utils/checkoutHelper";

import { postRequest } from "utils/ApiServices";

import classes2 from "./Checkout.module.css";

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // maxHeight: "00px",
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(1, 0, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["Required Information", "Payment details", "Review your order"];

const Checkout = ({ handleCloseParent }) => {
  const classes = useStyles();
  const childRef = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const [stripeToken, setStripeToken] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [selectedValue, setSelectedValue] = useState("99");
  const [selectedPackage, setSelectedPackage] = useState({
    reward: "Reward #1",
    text: "500 Prelaunch XCCESS (XCS) Cryptocurrency Tokens",
    tokens: 500,
  });
  const [state, setState] = useState({
    cvv: "",
    message: "",
    expYear: "",
    expMonth: "",
    cardNumber: "",
    formProcess: false,
  });
  const [state2, setState2] = useState({
    firstName: "",
    lastName: "",
    address: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    confirmemail: "",
    password: "",
  });
  const [tac_, setTAC_] = useState(false);
  const [coupon_, setCoupon] = useState("");
  const [isCouponApplied, setCouponApplied] = useState(false);
  const [couponOwnerDetail, setCouponOwnerDetail] = useState("");

  const getCoupon = async (coupon, type) => {
    setOpenLoader(true);
    const route = "/user/coupon";
    const payload = { coupon: coupon };
    try {
      const checkCoupon = await postRequest(route, payload);
      setOpenLoader(false);
      if (checkCoupon) {
        if (type === "coupon") {
          const price = parseFloat(selectedValue);
          var totalValue = price * ((100 - 10) / 100);
          setCouponApplied(true);
          setCouponOwnerDetail(checkCoupon);
          setSelectedValue(totalValue.toString());
          toast.success("Coupon Applied successfully");
        }
        return checkCoupon;
      } else {
        if (type === "coupon") {
          toast.error("Coupon not exist.");
        }
        return checkCoupon;
      }
    } catch (err) {
      setOpenLoader(false);
    }
  };

  const couponGenerator = async () => {
    let random = Math.random().toString().substring(2, 8);
    let found = await getCoupon(random);
    while (found) {
      random = Math.random().toString().substring(2, 8);
      found = await getCoupon(random, "");
    }
    return random;
  };

  const handleClose2 = (event, reason) => {
    handleCloseParent();
  };
  const onPaymentChangeHandler = (stateData) => {
    setState(stateData);
  };
  const onPackageChangeHandler = (reward, r, t, tt) => {
    setSelectedValue(reward);
    setSelectedPackage({
      ...selectedPackage,
      reward: r,
      text: t,
      tokens: tt,
    });
  };
  const onInformationChangeHandler = (stateData, type) => {
    if (type === "tac") {
      setTAC_(stateData);
    } else {
      setState2(stateData);
    }
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <RequiredInfo
            state={state2}
            tac={tac_}
            emailExists={emailExists}
            handleInformationChange={(stateData2, type) =>
              onInformationChangeHandler(stateData2, type)
            }
            ref={childRef}
          />
        );
      case 1:
        return (
          <PaymentForm
            state={state}
            selectedValue={selectedValue}
            onPackageChangeHandler={(rew, r, t, tt) => onPackageChangeHandler(rew, r, t, tt)}
            handlePaymentChange={(stateData) => onPaymentChangeHandler(stateData)}
            ref={childRef}
          />
        );
      case 2:
        return (
          <Review
            setCouponField={(cop) => getCoupon(cop, "coupon")}
            isCouponApplied={isCouponApplied}
            customerDetail={state2}
            amount={selectedValue}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }
  const loadStripe = () => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window["Stripe"].setPublishableKey(stripePublishableKey);
      };
      window.document.body.appendChild(s);
    }
  };
  React.useEffect(() => {
    loadStripe();
  }, []);

  const generateStripeToken = () => {
    setOpenLoader(true);
    window.Stripe.card.createToken(
      {
        number: state.cardNumber,
        exp_month: state.expMonth,
        exp_year: state.expYear,
        cvc: state.cvc,
      },
      (status, response) => {
        if (status === 200) {
          setStripeToken(response.id);
          toast.success("Your card is verified successfully.");

          setOpenLoader(false);
          setActiveStep(activeStep + 1);
        } else {
          toast.error(response.error.message);
          setOpenLoader(false);
        }
      }
    );
  };
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const isEmailExist = async (email) => {
    setOpenLoader(true);
    const route = "/user/isEmailExist";
    const payload = { email: email };
    try {
      const emailExists = await postRequest(route, payload);
      if (emailExists) {
        toast.error("Email already exist");
        setEmailExists(true);
        setOpenLoader(false);
      } else {
        setActiveStep(activeStep + 1);
        setOpenLoader(false);
      }
    } catch (err) {
      setOpenLoader(false);
    }
  };
  const handleNext = () => {
    let strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");

    if (
      state2.firstName !== "" &&
      state2.lastName !== "" &&
      state2.address !== "" &&
      state2.state !== "" &&
      state2.zip !== "" &&
      state2.email !== "" &&
      state2.email.toLowerCase() === state2.confirmemail.toLowerCase() &&
      validateEmail(state2.email) &&
      state2.password !== "" &&
      strongPassword.test(state2.password) &&
      tac_ &&
      activeStep === 0
    ) {
      const coupon = couponGenerator();
      coupon.then((result) => {
        setCoupon(result);
      });

      isEmailExist(state2.email);
    } else if (
      state.cardNumber !== "" &&
      state.cvv !== "" &&
      state.expMonth !== "" &&
      state.expYear !== "" &&
      activeStep === 1
    ) {
      generateStripeToken();
    } else {
      childRef.current.onFormSubmit();
      if (!validateEmail(state2.email) && state2.email !== "") {
        toast.error("Please enter valid email");
      } else if (state2.confirmemail.toLowerCase() !== state2.email.toLowerCase()) {
        toast.error("Confirm email does not match");
      } else if (!tac_) {
        toast.error("Please check terms and conditions.");
      } else if (state2.password.length < 8) {
        toast.error("Please enter at least 8 character for password");
      } else if (!strongPassword.test(state2.password)) {
        toast.error("See Password Requirements");
      } else {
        toast.error("Please fill all the required fields.");
      }
    }
  };

  const updateReferal = async () => {
    let commisionBonus = commissionCheck(
      couponOwnerDetail.payment.amount,
      parseFloat(selectedValue)
    );
    let referData = {
      userId: couponOwnerDetail._id,
      commission: commisionBonus.commission,
      bonus: commisionBonus.bonus,
      consumeByReferral: coupon_,
      consumeById: coupon_,
    };
    setOpenLoader(true);
    const route = "/user/updateReferal";
    try {
      await postRequest(route, referData);
      setOpenLoader(false);
    } catch (err) {
      setOpenLoader(false);
    }
  };

  const registerUser = async () => {
    let userData = {
      email: state2.email,
      firstname: state2.firstName,
      lastname: state2.lastName,
      phone: state2.phone,
      address: state2.address,
      state: state2.state,
      zipCode: state2.zip,
      payment: {
        status: true,
        amount: parseInt(selectedValue),
        reward: selectedPackage.reward,
        text: selectedPackage.text,
        tokens: selectedPackage.tokens,
      },
      coupon: coupon_,
      supporter: couponOwnerDetail?._id,
      password: state2.password,
    };
    setOpenLoader(true);
    const route = "/user/register";
    try {
      const createUserResp = await postRequest(route, userData);
      if (createUserResp) {
        setActiveStep(activeStep + 1);
        setOpenLoader(false);
        if (isCouponApplied) {
          updateReferal();
        }
        if (createUserResp.errmsg) {
          toast.error(createUserResp.errmsg);
        }
      }
    } catch (err) {
      setOpenLoader(false);
    }
  };
  const handleSubmit = async () => {
    let rewardPlan;
    if (selectedPackage.reward === "Reward #1") {
      rewardPlan = "XCCESS Reward Plan 1";
    } else if (selectedPackage.reward === "Reward #2") {
      rewardPlan = "XCCESS Reward Plan 2";
    } else if (selectedPackage.reward === "Reward #3") {
      rewardPlan = "XCCESS Reward Plan 3";
    } else if (selectedPackage.reward === "Reward #4") {
      rewardPlan = "XCCESS Reward Plan 4";
    }
    let stripeData = {
      amount: parseFloat(selectedValue),
      token: stripeToken,
      email: state2.email,
      rewardPlan: rewardPlan,
    };

    setOpenLoader(true);

    const route = "/user/stripe-payment";
    try {
      const stipeResp = await postRequest(route, stripeData);
      if (stipeResp) {
        registerUser();
        setOpenLoader(false);
      }
    } catch (err) {
      setOpenLoader(false);
      console.log("JJJJJJJJ = ", err.response);
      // toast.error(
      //   `${err.response.statusText}! ${err.response.data.errmsg.code}. ${err.response.data.errmsg.raw.message}`
      // );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <Loader isOpen={openLoader} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <div className={classes2.textMainDiv}>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your purchase.
                  </Typography>
                  <Typography variant="subtitle1">
                    Please check your email to confirm your email address to login.
                  </Typography>
                  <div className={classes2.impTextDiv}>
                    <p className={classes2.impText}>
                      <span>Important!</span> Check Junk Folder or Spam if you do not see email in
                      inbox.{" "}
                    </p>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button
                      onClick={activeStep === 2 ? handleClose2 : handleBack}
                      className={classes.button}
                    >
                      {activeStep === 2 ? "Close" : "Back"}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Place order" : "Next"}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default Checkout;
