import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useSelector } from "react-redux";

import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";
import GenericTable from "./GenericTable";

import classes2 from "../Dashboard.module.css";

import { getRequest, postRequest, patchRequest } from "utils/ApiServices";
import { parseError } from "utils/helpers";

const AdminTable = () => {
  const [userData_, setUserData] = useState();
  const [userSsn, setUserSsn] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [triggerState, setTriggerState] = useState(false);
  const [resetStatus, setResetStatus] = useState([]);
  const currentUser = useSelector((state) => state.currentUser);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const getResetRecordHistory = async () => {
    const route = "/user/resetStatus";
    try {
      const respData = await getRequest(route);
      setResetStatus(respData);
      setOpenLoader(false);
    } catch (err) {
      setOpenLoader(false);
    }
  };

  const onTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 3) {
      setOpenLoader(true);
      getResetRecordHistory();
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const formatDate = (dt) => {
    let date = new Date(dt).toLocaleDateString();
    let time = new Date(dt).toLocaleTimeString();
    return date + "  -  " + time;
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    getUserRequest(false);
  }, [triggerState]);

  const SsnHandler = (data, id) => {
    const foundSsn = data.find((dt) => dt._id === id);
    setUserSsn(foundSsn.ssn);
  };

  const getUserRequest = async (type, id) => {
    setOpenLoader(true);
    const route = "/user/all";
    const payload = { login: type };
    try {
      const respData = await postRequest(route, payload);
      setUserData(respData);
      setOpenLoader(false);
      if (type) {
        SsnHandler(respData, id);
      }
    } catch (err) {
      setOpenLoader(false);
    }
  };

  const onResetClick = async () => {
    var r = window.confirm("Are you sure to make change all the status to default?");
    if (r) {
      setOpenLoader(true);
      const route = "/user/changeStatusToDefault";
      const payload = { modifiedBy: currentUser?._id };
      try {
        await patchRequest(route, payload);
        setTriggerState(!triggerState);
        getResetRecordHistory();
        setOpenLoader(false);
        toast.success("Status Changed to default successfully");
      } catch (err) {
        setOpenLoader(false);
        toast.error(parseError(err));
      }
    }
  };

  return (
    <div>
      <Loader isOpen={openLoader} />
      <br />
      {["ceo", "super-admin"].includes(currentUser.usertype) && (
        <div style={{ textAlign: "center" }}>
          <Button variant="contained" color="secondary" size="small" onClick={onResetClick}>
            RESET Default Status to Incomplete
          </Button>
        </div>
      )}

      <br />
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {["ceo", "super-admin", "admin", "user"].includes(currentUser.usertype) && (
            <Tab label="Users" {...a11yProps(0)} />
          )}
          {["ceo", "super-admin", "admin"].includes(currentUser.usertype) && (
            <Tab label="Admins" {...a11yProps(1)} />
          )}
          {["ceo", "super-admin"].includes(currentUser.usertype) && (
            <Tab label="Super Admins" {...a11yProps(2)} />
          )}
          {["ceo"].includes(currentUser.usertype) && <Tab label="CEO" {...a11yProps(3)} />}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* Table for User */}
        {["ceo", "super-admin", "admin", "ceo"].includes(currentUser.usertype) && (
          <TabPanel value={value} index={value} dir={theme.direction}>
            <GenericTable
              title="Users"
              type="user"
              userData_={userData_?.filter((d) => d.usertype === "user")}
              setUserData={setUserData}
              triggerState={triggerState}
              setTriggerState={setTriggerState}
              userSsn={userSsn}
              setUserSsn={setUserSsn}
              getUserRequest={getUserRequest}
            />
          </TabPanel>
        )}
        {/* Table for admin */}
        {["ceo", "super-admin", "admin"].includes(currentUser.usertype) && (
          <TabPanel value={value} index={value} dir={theme.direction}>
            <GenericTable
              title="Admins"
              type="admin"
              userData_={userData_?.filter((d) => d.usertype === "admin")}
              setUserData={setUserData}
              triggerState={triggerState}
              setTriggerState={setTriggerState}
              userSsn={userSsn}
              setUserSsn={setUserSsn}
              getUserRequest={getUserRequest}
            />
          </TabPanel>
        )}
        {/* Table for super-admin */}
        {["ceo", "super-admin"].includes(currentUser.usertype) && (
          <TabPanel value={value} index={value} dir={theme.direction}>
            <GenericTable
              title="Super Admins"
              type="super-admin"
              userData_={userData_?.filter((d) => d.usertype === "super-admin")}
              setUserData={setUserData}
              triggerState={triggerState}
              setTriggerState={setTriggerState}
              userSsn={userSsn}
              setUserSsn={setUserSsn}
              getUserRequest={getUserRequest}
            />
          </TabPanel>
        )}
        {/* Table for ceo */}
        {["ceo"].includes(currentUser.usertype) && (
          <TabPanel value={value} index={value} dir={theme.direction}>
            <GenericTable
              title="CEO"
              type="ceo"
              userData_={userData_?.filter((d) => d.usertype === "ceo")}
              setUserData={setUserData}
              triggerState={triggerState}
              setTriggerState={setTriggerState}
              userSsn={userSsn}
              setUserSsn={setUserSsn}
              getUserRequest={getUserRequest}
            />
            <br />
            <TableContainer component={Paper}>
              <h2 className={classes2.tableHeading}>Reset Record History</h2>
              <Table className={classes2.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes2.tableHead} align="right">
                      Reset By
                    </TableCell>
                    <TableCell className={classes2.tableHead} align="left">
                      Reset At
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resetStatus?.map((row) => (
                    <TableRow key={row.resetAt}>
                      <TableCell align="right" width="50%">
                        {row?.resetBy?.email}
                      </TableCell>
                      <TableCell align="left" width="50%">
                        {formatDate(row?.resetAt)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {resetStatus?.length === 0 && <p style={{ textAlign: "center" }}>No Data</p>}
            </TableContainer>
          </TabPanel>
        )}
      </SwipeableViews>
    </div>
  );
};
export default AdminTable;
