import React from "react";

import { Button } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import classes from "../EditBonus.module.css";

const LicenceCard = ({ data, cardName }) => {
  const [userData, setUserData] = React.useState("");

  React.useEffect(() => {
    setUserData(data);
  }, [data]);

  return (
    <div className={classes.mainLicence}>
      <div className={classes.licenceCard}>
        <div className={classes.licenceHeader}>
          <div className={classes.container}>
            <p>{cardName}</p>
          </div>
        </div>

        <div className={classes.licenceBody}>
          <div className={classes.container}>
            <div className={classes.licenceImage}>
              {userData ? (
                cardName === "Contract" ? (
                  <PictureAsPdfIcon style={{ fontSize: "50px" }} />
                ) : (
                  <img src={userData} alt="licence" width="60px" />
                )
              ) : (
                <p>No data found</p>
              )}
            </div>
            {cardName === "License" ? (
              <a download="license" href={data}>
                {" "}
                <div className={classes.licenceDownload}>
                  <Button variant="contained" color="secondary" disabled={userData ? false : true}>
                    Download
                  </Button>
                </div>
              </a>
            ) : (
              <a href={data} rel="noreferrer" target="_blank">
                <div className={classes.licenceDownload}>
                  <Button variant="contained" color="secondary" disabled={userData ? false : true}>
                    Download
                  </Button>
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LicenceCard;
