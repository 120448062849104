import React, { useEffect, useState } from "react";
import classes from "./PaymentTypes.module.css";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";

import { getRequest, patchRequest } from "utils/ApiServices";

import paymentAppsBanner from "Assets/payment-apps-banner.jpg";

const PaymentTypes = () => {
  const [userIs, setUserIs] = useState();
  const [selectedMethod, setSelectedMethod] = useState("");
  const [method1Text, setMethod1Text] = useState("");
  const [method2Text, setMethod2Text] = useState("");
  const [method3Text, setMethod3Text] = useState("");
  const [openLoader, setOpenLoader] = React.useState(false);

  useEffect(() => {
    const userDataAPI = async () => {
      if (localStorage.getItem("user")) {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const route = `/user/userData/${currentUser._id}`;
        setOpenLoader(true);
        try {
          const userData = await getRequest(route);
          setOpenLoader(false);
          setUserIs(userData);
          setSelectedMethod(userData.paymentType);
          if (userData.paymentType === "method1") setMethod1Text(userData.paymentEmail);
          else if (userData.paymentType === "method2") setMethod2Text(userData.paymentEmail);
          else if (userData.paymentType === "method3") setMethod3Text(userData.paymentEmail);
        } catch (err) {
          setOpenLoader(false);
          toast.error("Something went wrong");
        }
      }
    };
    userDataAPI();
  }, []);

  const onTextFieldChange = (e) => {
    switch (e.target.name) {
      case "method1":
        setMethod1Text(e.target.value);
        break;
      case "method2":
        setMethod2Text(e.target.value);
        break;
      case "method3":
        setMethod3Text(e.target.value);
        break;

      default:
        break;
    }
  };

  const onSubmitHandler = async () => {
    if (!userIs) return;
    const payload = {
      _id: userIs._id,
      paymentType: "",
      paymentEmail: "",
    };
    switch (selectedMethod) {
      case "method1":
        payload.paymentType = selectedMethod;
        payload.paymentEmail = method1Text;
        break;
      case "method2":
        payload.paymentType = selectedMethod;
        payload.paymentEmail = method2Text;
        break;
      case "method3":
        payload.paymentType = selectedMethod;
        payload.paymentEmail = method3Text;
        break;

      default:
        break;
    }
    if (payload.paymentType !== "method3" && !validateEmail(payload.paymentEmail)) {
      toast.error("Email format is invalid!");
      return;
    }

    setOpenLoader(true);
    const route = "/user/addPaymentMethod";
    try {
      await patchRequest(route, payload);
      setOpenLoader(false);
      toast.success("Payment Method Successfully Saved");
    } catch (err) {
      setOpenLoader(false);
      toast.error("Something went wrong");
    }
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  return (
    <div className={classes.paymentTypeContainer}>
      <Loader isOpen={openLoader} />
      <p className={classes.paymentTitle}>
        Temporary Payment Method Until We Launch Our Digital Wallet
      </p>
      <img src={paymentAppsBanner} className={classes.PaymentApps} alt="payment apps" />

      <Grid container>
        <Grid item xs={1} className={classes.col1}>
          <input
            type="radio"
            name="payment-radio"
            value="method1"
            checked={selectedMethod === "method1" ? true : false}
            onChange={(e) => setSelectedMethod(e.target.value)}
          />
        </Grid>
        <Grid item xs={11} className={classes.col2}>
          <label>PayPal Details (Use Email)</label> <br />
          <input
            type="text"
            name="method1"
            defaultValue={method1Text}
            onChange={onTextFieldChange}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={1} className={classes.col1}>
          <input
            type="radio"
            name="payment-radio"
            value="method2"
            checked={selectedMethod === "method2" ? true : false}
            onChange={(e) => setSelectedMethod(e.target.value)}
          />
        </Grid>
        <Grid item xs={11} className={classes.col2}>
          <label>Zelle (Use Email)</label> <br />
          <input
            type="text"
            name="method2"
            defaultValue={method2Text}
            onChange={onTextFieldChange}
          />
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={1} className={classes.col1}>
          <input
            type="radio"
            name="payment-radio"
            value="method3"
            checked={selectedMethod === "method3" ? true : false}
            onChange={(e) => setSelectedMethod(e.target.value)}
          />
        </Grid>
        <Grid item xs={11} className={classes.col2}>
          <label>CASH APP</label> <br />
          <input
            type="number"
            name="method3"
            defaultValue={method3Text}
            onChange={onTextFieldChange}
          />
        </Grid>
      </Grid>

      <p className={classes.importantNoteText}>
        IMPORTANT NOTE: By submitting your payment details, you agree to accept full responsibility
        for entering incorrect info. Our company is NOT liable for monies sent to the wrong party
        based on a payment detail error you provided.
      </p>

      <Button variant="contained" className={classes.submitBtn} onClick={onSubmitHandler}>
        Submit
      </Button>
    </div>
  );
};

export default PaymentTypes;
