import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";

import Loader from "components/Loader/Loader";
import toast from "components/Toast/Toast";
import EditModal from "./EditModal";
import SsnLogin from "./SsnLogin";

import { patchRequest, deleteRequest } from "utils/ApiServices";
import { parseError } from "utils/helpers";

import classes2 from "../Dashboard.module.css";

const GenericTable = (props) => {
  const history = useHistory();
  const [openLoader, setOpenLoader] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  const onDelete = async (id, userEmail) => {
    var r = false;
    r = window.confirm("Are you sure to delete this user?");
    if (r) {
      setOpenLoader(true);
      if (id !== currentUser?._id) {
        const route = "/user/delete";
        const payload = {
          data: {
            id: id,
            password: "",
          },
        };
        try {
          await deleteRequest(route, payload);
          setOpenLoader(false);
          props?.getUserRequest(false);
        } catch (err) {
          setOpenLoader(false);
        }
      }
    }
  };
  const onViewSsn = async (type, id) => {
    props?.getUserRequest(true, id);
  };

  const onChangeUserType = async (id, newType) => {
    var r = window.confirm("Are you sure to make this user as Admin");
    if (r) {
      setOpenLoader(true);
      const route = "/admin/updateUserType";
      const payload = {
        id: id,
        usertype: newType,
      };
      try {
        await patchRequest(route, payload);
        setOpenLoader(false);
        toast.success("User Type Changed successfully.");
        props?.getUserRequest(false);
      } catch (err) {
        setOpenLoader(false);
        if (err.response) {
          toast.error(parseError(err));
        }
      }
    } else {
    }
  };

  const formatDate = (dt) => {
    let date = new Date(dt).toLocaleDateString();
    return date;
  };

  const goTo = (userId) => {
    history.push(`/admin/user/${userId}`);
  };

  const handleStatusChange = async (e, userId) => {
    setOpenLoader(true);
    const route = "/user/changeStatus";
    const payload = {
      _id: userId,
      modifiedBy: currentUser?._id,
      isCompleted: e.target.value === "completed" ? true : false,
    };
    try {
      await patchRequest(route, payload);
      props?.setTriggerState(!props?.triggerState);
      setOpenLoader(false);
    } catch (err) {
      setOpenLoader(false);
    }
  };

  const del = (row) => (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      onClick={() => onDelete(row._id, row?.email)}
      disabled={row._id === currentUser?._id ? true : false}
    >
      Delete
    </Button>
  );
  const edit = (row) => (
    <EditModal
      userData={row}
      //   getUserRequest={() => props?.getUserRequest(false)}
    />
  );

  const viewssn = (row) => (
    <SsnLogin
      id={row._id}
      userSsn={props?.userSsn}
      setUserSsn={props?.setUserSsn}
      onViewSsn={(type, id) => onViewSsn(type, id)}
      userData={row}
      userType={currentUser?.usertype}
    />
  );
  const makeAdmin = (row) => (
    <Button
      size="small"
      variant="contained"
      color="secondary"
      onClick={() => onChangeUserType(row._id, "admin")}
    >
      Make Admin
    </Button>
  );
  const makeSuperAdmin = (row) => (
    <Button
      size="small"
      variant="contained"
      color="secondary"
      onClick={() => onChangeUserType(row._id, "super-admin")}
    >
      Make Super Admin
    </Button>
  );

  const actionRenderer = (row) => {
    var arr = [];
    switch (currentUser?.usertype) {
      case "admin":
        if (props?.type === "user") arr.push(del(row), edit(row), makeAdmin(row));
        else if (props?.type === "admin" && row._id === currentUser?._id) arr.push(edit(row));
        else if (props?.type === "super-admin") arr = [];
        else if (props?.type === "ceo") arr = [];
        break;

      case "super-admin":
        if (props?.type === "user") arr.push(del(row), edit(row), viewssn(row), makeAdmin(row));
        else if (props?.type === "admin")
          arr.push(del(row), edit(row), viewssn(row), makeSuperAdmin(row));
        else if (props?.type === "super-admin" && row._id === currentUser?._id) arr.push(edit(row));
        else if (props?.type === "ceo") arr = [];
        break;

      case "ceo":
        if (props?.type === "user") arr.push(del(row), edit(row), viewssn(row), makeAdmin(row));
        else if (props?.type === "admin")
          arr.push(del(row), edit(row), viewssn(row), makeSuperAdmin(row));
        else if (props?.type === "super-admin") arr.push(del(row), edit(row), viewssn(row));
        else if (props?.type === "ceo") arr.push(edit(row), viewssn(row));
        break;

      default:
        break;
    }

    return arr.length !== 0 && <div className={classes2.actionFlex}>{arr.map((d) => d)}</div>;
  };

  const statusDisableDecider = (row) => {
    if (currentUser.usertype === "ceo") return false;
    else if (row.isCompleted) return true;
  };

  return (
    <div>
      <Loader isOpen={openLoader} />

      <TableContainer component={Paper}>
        <h2 className={classes2.tableHeading}>{props?.title}</h2>
        <Table style={{ minWidth: "650px" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell className={classes2.tableHead} align="right">
                Supporter
              </TableCell>
              <TableCell className={classes2.tableHead} align="right">
                Full Address
              </TableCell>
              <TableCell className={classes2.tableHead} align="right">
                Email
              </TableCell>
              <TableCell className={classes2.tableHead} align="right">
                Referrer's Code
              </TableCell>
              <TableCell className={classes2.tableHead} align="right">
                6 Digit Code
              </TableCell>
              <TableCell className={classes2.tableHead} align="right">
                Status
              </TableCell>
              <TableCell className={classes2.tableHead} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.userData_?.map((row) => (
              <TableRow key={row.createdAt}>
                <TableCell component="th" scope="row">
                  {formatDate(row.createdAt)}
                </TableCell>
                <TableCell
                  align="right"
                  onClick={() => goTo(row._id)}
                  className={classes2.nameRow}
                >{`${row.firstname} ${row.lastname}`}</TableCell>
                <TableCell align="right">
                  {row.address}, {row.state}, {row.zipCode}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell
                  align="right"
                  onClick={() => goTo(row.supporter?._id)}
                  className={classes2.nameRow}
                >
                  {row.supporter?.coupon}
                </TableCell>
                <TableCell align="right">{row.coupon}</TableCell>
                <TableCell align="right">
                  <Select
                    native
                    value={row.isCompleted ? "completed" : "incomplete"}
                    onChange={(e) => handleStatusChange(e, row._id)}
                    disabled={statusDisableDecider(row)}
                    label="Age"
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                    style={{ width: "110px" }}
                  >
                    <option value="incomplete">Incomplete</option>
                    <option value="completed">Completed</option>
                  </Select>
                </TableCell>
                <TableCell align="right">{actionRenderer(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {props?.userData_?.length === 0 && <p style={{ textAlign: "center" }}>No Data</p>}
      </TableContainer>
    </div>
  );
};

export default GenericTable;
