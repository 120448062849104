import React, { useState } from "react";
import Countdown from "react-countdown";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

import toast from "components/Toast/Toast";

import { postRequest, patchRequest } from "utils/ApiServices";
import { parseError } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "90px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 450,
    height: 400,
  },
  emailFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flex1: {
    flexBasis: "70%",
  },
  list: {
    fontSize: "11px",
  },
}));

const ForgetPassword = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [dateNow, setDateNow] = useState(Date.now());
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [codeSpinner, setCodeSpinner] = useState(false);

  const tooltipText = (
    <ul className={classes.list}>
      <li>Length should be at least 8 characters</li>
      <li>Should contain at least one upper case letter</li>
      <li>Should contain at least one lower case letter</li>
      <li>Should contain at least one special character</li>
      <li>Should contain at least one digit</li>
    </ul>
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleChange = (evt) => {
    setIsValid(false);
    if (evt.target.name === "email") {
      setEmail(evt.target.value);
    } else if (evt.target.name === "password") {
      setPassword(evt.target.value);
    } else if (evt.target.name === "code") {
      setCode(evt.target.value);
    }
  };

  const onResetHandler = async () => {
    setIsValid(true);
    if (email !== "" && validateEmail(email) && code !== "" && password !== "") {
      const route = "/user/reset";
      const payload = {
        code: code,
        email: email,
        password: password,
      };
      try {
        await patchRequest(route, payload);
        toast.success("Your password is changed successfully");
      } catch (err) {
        toast.error(parseError(err));
      }
    } else {
      toast.error("Please fill required fields or check Email format!");
    }
  };
  const getCode = async () => {
    setCodeSpinner(true);
    const route = "/user/forget";
    const payload = { email: email };
    try {
      await postRequest(route, payload);
      toast.success("Please check you email for code.");
      setDateNow(Date.now());
      setShowTimer(true);
      setCodeSpinner(false);
    } catch (err) {
      setCodeSpinner(false);
      toast.error("Some error has occured. Please try again!");
    }
  };
  return (
    <div>
      <span className="spanLinkStyles" onClick={handleOpen}>
        Lost your password?
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Forget Password
            </Typography>
            <div className={classes.fpForm}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.emailFlex}>
                    <div className={classes.flex1}>
                      <TextField
                        required
                        error={
                          email === "" && isValid
                            ? true
                            : !validateEmail(email) && email.length > 0
                            ? true
                            : false
                        }
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={handleChange}
                        helperText={
                          email === "" && isValid
                            ? "Email is required."
                            : !validateEmail(email) && email.length > 0
                            ? "Email is not valid"
                            : null
                        }
                      />
                    </div>
                    <div className={classes.flex2}>
                      {!codeSpinner ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={getCode}
                          disabled={!validateEmail(email) && email.length > 0}
                        >
                          Get code
                        </Button>
                      ) : (
                        <CircularProgress />
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={password === "" && isValid ? true : false}
                    id="password"
                    name="password"
                    label="New Password"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={handleChange}
                    helperText={password === "" && isValid ? "Password is required." : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={code === "" && isValid ? true : false}
                    id="code"
                    name="code"
                    label="Code"
                    type="text"
                    fullWidth
                    value={code}
                    onChange={handleChange}
                    helperText={code === "" && isValid ? "Password is required." : null}
                  />
                </Grid>

                <br />
                <br />
                <br />
                <br />

                <Grid>
                  <Tooltip title={tooltipText}>
                    <Button size="small" style={{ backgroundColor: "#fc4c4c", color: "white" }}>
                      Password Requirements?
                    </Button>
                  </Tooltip>
                </Grid>

                <br />
                <br />

                {showTimer && (
                  <Grid>
                    <p style={{ margin: "10px 0px" }}>
                      Code will expire in:{" "}
                      <Countdown date={dateNow + 60000} onComplete={() => setShowTimer(false)} />
                    </p>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={onResetHandler}>
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default ForgetPassword;
