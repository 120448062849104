import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import EditBonusModal from "./EditBonusModal";

import classes from "../EditBonus.module.css";

const BonusTable = ({ data, userData, userTypeIs, getUserBonus }) => {
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    setTableData(data);
  }, [data]);

  const actionRenderer = (row) => {
    switch (userTypeIs) {
      case "admin":
        if (["user"].includes(userData?.usertype))
          return <EditBonusModal bonus={row} getUserBonus={getUserBonus} />;
        break;

      case "super-admin":
        if (["user", "admin"].includes(userData?.usertype))
          return <EditBonusModal bonus={row} getUserBonus={getUserBonus} />;
        break;

      case "ceo":
        if (["user", "admin", "super-admin", "ceo"].includes(userData?.usertype))
          return <EditBonusModal bonus={row} getUserBonus={getUserBonus} />;
        break;

      default:
        break;
    }
  };

  const formatDate = (dt) => {
    let date = new Date(dt).toLocaleDateString();
    let time = new Date(dt).toLocaleTimeString();
    return date + "  -  " + time;
  };

  return (
    <div style={{ height: "100%" }}>
      <TableContainer component={Paper} style={{ height: "100%" }}>
        <Table className={classes.bonusTable} aria-label="simple table" style={{ height: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="center">Commission</TableCell>
              <TableCell align="center">ID#</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">XCCESS Cryptocurrency</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length !== 0 ? (
              tableData.map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {formatDate(row.createdAt)}
                  </TableCell>
                  <TableCell align="center">{row.commission}</TableCell>
                  <TableCell align="center">{row.consumeByReferral}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">{row.bonus}</TableCell>
                  <TableCell align="center">{actionRenderer(row)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <h2>No Data</h2>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default BonusTable;
