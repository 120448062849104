import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <p>ALL COPYRIGHTS RESERVED. 2022-2023 XCCESS, LLC, a subsidiary of Remote Xccess, LLC.</p>
    </div>
  );
};
export default Footer;
