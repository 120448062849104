import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import styles from "../Checkout.module.css";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review({ setCouponField, isCouponApplied, amount, customerDetail }) {
  const classes = useStyles();
  const [coupon_, setCoupon] = React.useState("");
  const [originalAmount, setOriginalAmount] = React.useState("");

  React.useEffect(() => {
    setOriginalAmount(amount);
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <div className={styles.coupon}>
        <Typography variant="h6" gutterBottom>
          Got a Coupon? Enter it to avail 10% discount.
        </Typography>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="code"
              name="code"
              label="Coupon Code"
              fullWidth
              value={coupon_}
              onChange={(e) => setCoupon(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCouponField(coupon_)}
              disabled={isCouponApplied ? true : false}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </div>
      <List disablePadding>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={
              originalAmount === "99"
                ? "Reward#1"
                : originalAmount === "299"
                ? "Reward#2"
                : originalAmount === "499"
                ? "Reward#3"
                : "Reward#4"
            }
            secondary={
              originalAmount === "99"
                ? "500 XCCESS Tokens"
                : originalAmount === "299"
                ? "1500 XCCESS Tokens"
                : originalAmount === "499"
                ? "2500 XCCESS Tokens"
                : "5000 XCCESS Tokens"
            }
          />
          <Typography variant="body2">${originalAmount}</Typography>
        </ListItem>
        {isCouponApplied && (
          <ListItem className={classes.listItem}>
            <ListItemText primary="Discount" />
            <Typography variant="subtitle1" className={classes.total}>
              - ${originalAmount * 0.1}
            </Typography>
          </ListItem>
        )}

        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            ${parseFloat(amount).toFixed(2)}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Address
          </Typography>
          <Typography
            gutterBottom
          >{`${customerDetail.firstName} ${customerDetail.lastName}`}</Typography>
          <Typography gutterBottom>{customerDetail.address}</Typography>
          <Typography gutterBottom>{customerDetail.state}</Typography>
          <Typography gutterBottom>{customerDetail.zip}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
