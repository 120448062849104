import axios from "../config/axiosSet";
import isFunction from "lodash/isFunction";
import size from "lodash/size";
import logoutHandler from "./logoutHandler";

const API_BASE_PATH = "http://localhost:5000";

const initVal = {
  _cancelToken: null,
};

const _cancelToken = initVal._cancelToken;

const requestTokens = {};

const CancelToken = axios.CancelToken;

const failedResponse = (error, callback) => {
  // if (LOGOUT_ON_API_401 && error.response && error.response.status && error.response.status === 401) {
  if (error.response && error.response.status) {
    if (error.response.status === 401 || error.response.status === 403) logoutHandler();
  }
  if (typeof error === "string") {
    return Promise.reject(error);
  }
  const data = error.response && error.response.data ? error.response.data : {};
  if (callback && isFunction(callback)) {
    callback(data);
  }
  return Promise.reject(data);
};

// getRequest
export const getRequest = (routePath, callback, config) => {
  const route = `${routePath}`;
  let axiosConfig = {};
  if (!route) {
    return Promise.reject();
  }
  if (config && size(config) > 0) {
    axiosConfig = {
      ...config,
    };
  }

  return axios
    .get(route, { ...axiosConfig })
    .then((response) => {
      // _cancelToken = initVal._cancelToken;
      if (callback && isFunction(callback)) {
        callback(response.data);
      }
      return response.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error);
      }
      return failedResponse(error, callback);
    });
};

export const postRequest = (routePath, data = {}, config, callback) => {
  const route = `${routePath}`;
  requestTokens[route] = CancelToken.source();
  return axios
    .post(route, data, config)
    .then((response) => {
      if (callback && isFunction(callback)) {
        callback(response.data);
      }
      return response.data;
    })
    .catch((error) => {
      return failedResponse(error, callback);
    });
};

// patchRequest
export const patchRequest = (routePath, data, callback) => {
  const route = `${routePath}`;
  return axios
    .patch(route, data)
    .then((response) => {
      if (callback && isFunction(callback)) {
        callback(response.data);
      }
      return response.data;
    })
    .catch((error) => {
      return failedResponse(error, callback);
    });
};

// putRequest
export const putRequest = (routePath, data = {}, config, callback) => {
  const route = `${routePath}`;
  return axios
    .put(route, data, config)
    .then((response) => {
      if (callback && isFunction(callback)) {
        callback(response.data);
      }
      return response.data;
    })
    .catch((error) => {
      return failedResponse(error, callback);
    });
};

// deleteRequest
export const deleteRequest = (routePath, data = {}, callback) => {
  const route = `${routePath}`;
  return axios
    .delete(route, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return failedResponse(error, callback);
    });
};

// deleteRequest
const cancelRequest = (type) => {
  //   const route = getRoute(type);
  //   if (requestTokens[route]) requestTokens[route].cancel();
};
