import React from "react";
import Hero from "./components/Hero/Hero";
import FAQs from "./components/FAQs/FAQs";
import Footer from "components/Footer/Footer";

const Homepage = () => {
  return (
    <div>
      <Hero />
      <FAQs />
      <Footer />
    </div>
  );
};

export default Homepage;
