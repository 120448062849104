const counterReducer = (state = 0, action) => {
  switch (action.type) {
    case "INCREASE_COUNTER":
      state = state + 1;
      return state;

    case "DECREASE_COUNTER":
      state = state - 1;
      return state;

    default:
      return state;
  }
};

export default counterReducer;
