import classes from "../EditBonus.module.css";
import React from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const LicenceCard = ({ userData }) => {
  return (
    <div className={classes.mainLicence}>
      <div className={classes.licenceCard}>
        <div className={classes.licenceHeader}>
          <div className={classes.container}>
            <p>Payment Details</p>
          </div>
        </div>

        <div style={{ padding: "40px 10px 10px 10px", overflow: "auto" }}>
          {userData ? (
            <div style={{}}>
              <Grid container>
                <Grid item xs={3}>
                  <p className={classes.paymentTitle}>PayPal: </p>
                </Grid>
                <Grid item xs={9}>
                  {userData.paymentType === "method1" ? (
                    <p>{userData.paymentEmail}</p>
                  ) : (
                    <TextField size="small" variant="outlined" disabled />
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <p className={classes.paymentTitle}>Zelle: </p>
                </Grid>
                <Grid item xs={9}>
                  {userData.paymentType === "method2" ? (
                    <p>{userData.paymentEmail}</p>
                  ) : (
                    <TextField size="small" variant="outlined" disabled />
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <p className={classes.paymentTitle}>Cash App: </p>
                </Grid>
                <Grid item xs={9}>
                  {userData.paymentType === "method3" ? (
                    <p>{userData.paymentEmail}</p>
                  ) : (
                    <TextField size="small" variant="outlined" disabled />
                  )}
                </Grid>
              </Grid>
            </div>
          ) : (
            <p>No data found</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default LicenceCard;
