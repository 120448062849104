import classes from "./ComingSoon.module.css";

import mobilePhone1 from "Assets/mobile-phone-xrace.jpg";
// import mobilePhone3 from "Assets/mobile-phone3.jpg";

const Comingsoon = () => {
  return (
    <div className={classes.comingSoon}>
      <div className={classes.commissionDiv}>
        <div className={classes.commingSoonDiv}>
          <span>Coming Soon!!</span>
        </div>
      </div>
      <div className={classes.mobileImagesContainer}>
        <div>
          <img src={mobilePhone1} width="70%" alt="mobilePhone1" />
        </div>
        {/* <div>
          <img src={mobilePhone3} width="25%" alt="mobilePhone3" />
        </div> */}
      </div>
    </div>
  );
};
export default Comingsoon;
