import React, { useState, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
}));

const Loader = ({ isOpen }) => {
  const classes = useStyles();
  const [openLoader, setOpenLoader] = useState(isOpen);

  useEffect(() => {
    setOpenLoader(isOpen);
  }, [isOpen]);

  const handleCloseLoader = () => {
    setOpenLoader(false);
  };

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={openLoader}
        // onClick={handleCloseLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default Loader;
