import axios from "axios";
import { apiURL } from "config/config";

axios.defaults.baseURL = apiURL;

axios.interceptors.request.use((config) => {
  config.withCredentials = true;
  return config;
});

export default axios;
