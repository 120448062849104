const objStructure = {
  _id: "",
  email: "",
  firstname: "",
  lastname: "",
  address: "",
  token: "",
  usertype: "",
  payment: {
    status: "",
    amount: "",
    reward: "",
    text: "",
    tokens: "",
  },
  coupon: "",
  paymentType: "",
  paymentEmail: "",
};

export default function checkCurrentUserKeys(currentUser) {
  var obj1Keys = Object.keys(currentUser).sort();
  var obj2Keys = Object.keys(objStructure).sort();
  // return JSON.stringify(obj1Keys) === JSON.stringify(obj2Keys);
  return true;
}
