import React from "react";
import classes from "../PaidSubDash/PaidSubDash.module.css";
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";

import Leftbar from "../PaidSubDash/components/Leftbar/Leftbar";
import PaymentTypes from "./components/PaymentTypes";
import Comingsoon from "../PaidSubDash/components/ComingSoon/ComingSoon";

const CommissionPaymentMethod = () => {
  // 70px is Navbar Height. 50px is Footer Height
  const wrapperStyle = {
    textAlign: "center",
    backgroundColor: "black",
    minHeight: `calc(100vh - 70px - 50px)`,
    display: "flex",
  };

  return (
    <div>
      <div className={classes.background}>
        <Container className={classes.container}>
          <Navbar />
          <div style={wrapperStyle}>
            <Grid container>
              <Grid item md={4} xs={12}>
                <Leftbar />
              </Grid>
              <Grid item md={4} xs={12}>
                <PaymentTypes />
              </Grid>
              <Grid item md={4} xs={12}>
                <Comingsoon />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default CommissionPaymentMethod;
