import React, { useState, useEffect } from "react";
import { useFileUpload } from "use-file-upload";

import Button from "@material-ui/core/Button";

import toast from "components/Toast/Toast";

import classes from "./Required.module.css";

const Required = ({ submitForm, data }) => {
  const [file, selectFile] = useFileUpload();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (file) {
      if (!["image/png", "image/jpg", "image/jpeg"].includes(file.file.type)) {
        toast.error("Invalid file type");
      } else if (file.file.size > 3 * 1024 * 1024) {
        toast.error("Image should be less than 3 MBs");
      } else {
        setSelectedFile(file);
      }
    }
  }, [file]);

  const onSubmitClick = () => {
    if (!selectedFile && !data.license) {
      return toast.error("Image is required before submitting");
    }
    submitForm(selectedFile);
  };

  const getImageLink = () => {
    if (selectedFile) return selectedFile.source;
    if (data.license) return data.license;
  };

  return (
    <div style={{ padding: "10px" }}>
      <div className={classes.border}>
        <div className={classes.header}>Required Identification Information</div>
        <p>
          <span className={classes.bulletPoints}>
            UPLOAD CLEAR IMAGE OF FRONT OF <br />
            YOUR DRIVER'S LICENSE OR STATE ID.
          </span>
        </p>
        <p>
          <span className={classes.warning}>(Note: Image size should be less than 3MB)</span>
        </p>
        {selectedFile || data.license ? (
          <div>
            <img src={getImageLink()} width="20%" alt="preview" />
          </div>
        ) : (
          <span>No file selected</span>
        )}
        <div className={classes.buttonDiv}>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="secondary"
            onClick={() => selectFile({ accept: "image/jpg,image/jpeg,image/png" })}
          >
            UPLOAD
          </Button>
        </div>
      </div>
      <div className={classes.buttonFlex}>
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={onSubmitClick}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Required;
