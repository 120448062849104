import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setCurrentUser } from "redux/actions";
import logoutHandler from "utils/logoutHandler";
import checkCurrentUserKeys from "utils/checkCurrentUserKeys";

function AdminAuth({ component: Component, ...restOfProps }) {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    if (currentUser && checkCurrentUserKeys(currentUser)) {
      dispatch(setCurrentUser());
      if (["ceo", "super-admin", "admin"].includes(currentUser.usertype)) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } else {
      logoutHandler();
    }
  }, []);

  return (
    <Route
      {...restOfProps}
      render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
}

export default AdminAuth;
