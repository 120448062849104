import React from "react";

import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";

import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <Navbar />
        <div className={classes.contentBackground}>
          <div className={classes.contentContainer}>
            <h2>PRIVACY POLICY </h2>
            <h3>JANUARY 2022 </h3>
            <p>
              XCCESS, LLC referred hereafter as (“XCCESS,” the “COMPANY”, “WE”, “US” or “OUR”)
              operates MYXFUND.com (“WEBSITE” or “SERVICE”) and mobile app. XCCESS and our parent
              company, Remote Xccess, LLC is dedicated to protecting the privacy of our users and
              securing any personal data. Users of our website and app may be referred to as
              (“RECIPIENT”, “YOU”, “YOUR” and “VISITOR”).
              <br />
              <br />
              This Privacy Policy informs users of our policies regarding the collection, use and
              disclosure of personal data and identifiable information when using our website. We do
              not use or share your information with anyone except as described in this Privacy
              Policy. By using our website or services, you agree to the collection and use of this
              information in accordance with this Privacy Policy.
              <br />
              <br />
              <strong>DATA COLLECTION AND USE:</strong>
              <br />
              <br />
              During the use of our website platform, we may ask users to provide us with specific
              identifiable personal information for the purpose of authenticating your identity and
              to comply with the Internal Revenue (IRS) regarding any revenue you’ve earned through
              our Company. This also protects the user’s interest by ensuring secure transfer of
              XCCESS Cryptocurrency is made to the rightful owner and the correct recipient is
              compensated to reduce fraud. Identifiable information may include but is not limited
              to your legal name, email address, phone number, postal address, and other personal
              identifiable information for tax reporting purposes. We also use this information to
              communicate with you by replying to your inquiries, servicing your account, and
              improving our services.
              <br />
              <br />
              <strong>LOGGING DATA:</strong>
              <br />
              <br />
              We may also collect information your browser sends when you visit our Site by logging
              data. The data might include your Internet Protocol (“IP”) address, browser type or
              version, the time stamp date of your visit, the amount of time spent on specific pages
              and other relevant statistical data. Furthermore, we may utilize third party service
              such as Google Analytics’ which have their own privacy policy to collect, analyze and
              monitor user data for the purpose of improving our services.
              <br />
              <br />
              <strong>COOKIES: </strong>
              <br />
              <br />
              Cookies are sent to your browser from a website and installed on your device platform.
              They are files with a minimal amount of data which may consist of an anonymous unique
              identifier. The Company uses cookies to collect data to improve our quality of
              services. Visitors can refuse all cookies and use the help feature on your browse to
              disable cookies. The Company supports DO NOT TRACK (“DNT”). You can set-up in your web
              browser preferences to instruct websites you don’t want to be tracked.
              <br />
              <br />
              <strong>COMMUNICATIONS: </strong>
              <br />
              <br />
              Our Company may use your personal information to contact you regarding beneficial
              opportunities, virtual meetings, updates relating to our progress in pursuit of our
              mission and related to your XCCESS account.
              <br />
              <br />
              <strong>COMPLIANCE WITH THE LAW:</strong>
              <br />
              <br />
              We may be required by law to disclose your personal information if compelled to do so
              by subpoena if we believe such action is necessary to comply with the request of law
              enforcement.
              <br />
              <br />
              <strong>SECURITY: </strong>
              <br />
              <br />
              We implement and maintain, commercially acceptable security procedures and practices
              appropriate for data storage to protect it from unauthorized access, destruction, use,
              modification, and disclosure. But no method of electronic storage over the internet is
              100% secure therefore we cannot make any guarantees.
              <br />
              <br />
              <strong>CHANGES TO PRIVACY POLICY:</strong>
              <br />
              <br />
              Our Company reserves the right to update this Privacy Policy at any time. You should
              check for updates. Your continual use of our website after updates to our Privacy
              Policy constitutes your acknowledgement and consent to abide and be bound by the
              updated Privacy Policy.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PrivacyPolicy;
